import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/blogDetail";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink, useParams } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import useScrollTop from "../hooks/useScrollTop";

const BlogDetailContainer = () => {
  const { id } = useParams();
  const data = useSelector((state) => state.blogDetail.content);
  const isLoading = useSelector((state) => state.blogDetail.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings, blog_detail } = data;

  useEffect(() => {
    dispatch(fetchData(id));
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);

  useDocumentTitle(data.page_title);
  useScrollTop();

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index rescue">
            <div className="mini-label">
              <div className="contain">
                <ul>
                  <li>
                    <Link to="/help-and-resources">Help &amp; Resources</Link>
                  </li>
                  <li>
                    <Link to="/current-affairs">Current Affairs</Link>
                  </li>
                </ul>
              </div>
            </div>
            <section
              className="sub-banner rescue_stroy"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="cntnt">
                  <h1>
                    <Text string={content.banner_heading} />
                  </h1>
                  <p>
                    <Text string={content.banner_desc} />
                  </p>
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="rescue-sec">
              <div className="contain">
                <div className="rescue-flex flex">
                  <div className="text">
                    <h4>
                      <Text string={content.banner_card_heading} />
                    </h4>
                    <p>
                      <Text string={content.banner_card_detail} />
                    </p>
                  </div>
                  <div className="bTn">
                    <Link
                      to={content.banner_card_button_link}
                      className="webBtn"
                    >
                      <Text string={content.banner_card_button_text} />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            {/*rescue section */}
            <section id="story_detail">
              <div className="contain">
                <div className="flex">
                  <div className="colL">
                    <div className="inner">
                      <h1>
                        <Text string={blog_detail.title} />
                      </h1>
                      <Text
                        string={blog_detail.short_description}
                        parse={true}
                      />
                      <div className="image">
                        <ImageControl folder="vlogs" src={blog_detail.image} />
                      </div>
                      <Text string={blog_detail.description} parse={true} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*  Story detils */}
            <section className="map">
              <div className="contain  text-center">
                <h1>
                  <Text string={content.section3_heading} />
                </h1>
                <div className="cntntt">
                  <h5 className="regular">
                    <Text string={content.section3_detail} />
                  </h5>
                </div>
                <div className="bTn margn_bottom_30">
                  <Link
                    to={content.section3_upper_button_link}
                    className="webBtn"
                  >
                    <Text string={content.section3_upper_button_text} />
                  </Link>
                </div>
                <p>
                  <Link to={content.section3_lower_button_link}>
                    <Text string={content.section3_lower_button_text} />
                  </Link>
                </p>
              </div>
            </section>
            {/* map */}
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default BlogDetailContainer;

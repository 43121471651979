import React, { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/whatIsHumanTraffik";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import useScrollTop from "../hooks/useScrollTop";

const WhatIsHumanTraffik = () => {
  const data = useSelector((state) => state.whatIsHumanTraffik.content);
  const isLoading = useSelector((state) => state.whatIsHumanTraffik.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  useDocumentTitle(data.page_title);
  useScrollTop();
  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index">
            <div className="mini-label">
              <div className="contain">
                <ul>
                  <li>
                    <Link to="/what-is-human-trafficking">
                      About Sex &amp; Trafficking
                    </Link>
                  </li>
                  <li>
                    <Link to="/what-is-human-trafficking">
                      What is human trafficking?
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <section
              className="sub-banner human-trafficking-page"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="cntnt">
                  <h1>
                    <Text string={content.banner_heading} />
                  </h1>
                  <Text string={content.banner_desc} parse={true} />
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="cmn-tabs">
              <div className="contain">
                <div className="flex">
                  <div className="colL">
                    <div className="tabs-lst">
                      <ul>
                        <li className="active">
                          <NavLink
                            to="/what-is-human-trafficking"
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            What is human trafficking?
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/what-is-sex-trafficking"
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            What is Sex trafficking?
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/fact-and-statistics"
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            FACTS, AND STATISTICS
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/policy-and-legislation"
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            POLICY &amp; LEGISLATION
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="side-poster relative">
                      <div className="image">
                        <ImageControl folder="images" src={content.image2} />
                      </div>
                      <div className="cntnt">
                        <div className="immage">
                          <ImageControl folder="images" src={content.image3} />
                        </div>
                        <h2>
                          <Text string={content.sec2_pu_heading} />
                        </h2>
                        <p>
                          <Text string={content.sec2_pu_desc} />
                        </p>
                        <div className="bTn">
                          <Link
                            to={content.sec2_pu_button_link}
                            className="webBtn"
                          >
                            {content.sec2_pu_button_text}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="colR">
                    <div className="inner flex">
                      <div className="left">
                        <ImageControl
                          folder="images"
                          src={content.image4}
                          isThumb={true}
                          isLazy={true}
                        />
                      </div>
                      <div className="right">
                        <p>
                          <Text string={content.sec2_right1_right_text} />
                        </p>
                      </div>
                      <div className="full-txt">
                        <p>
                          <Text string={content.sec2_right1_right_desc} />
                        </p>
                      </div>
                    </div>
                    <div className="inner flex">
                      <div className="left">
                        <ImageControl
                          folder="images"
                          src={content.image5}
                          isThumb={true}
                          isLazy={true}
                        />
                      </div>
                      <div className="right">
                        <p>
                          <Text string={content.sec2_right2_right_text} />
                        </p>
                      </div>
                      <div className="full-txt">
                        <p>
                          <Text string={content.sec2_right2_right_desc} />
                        </p>
                      </div>
                    </div>
                    <div className="inner flex">
                      <div className="left">
                        <ImageControl
                          folder="images"
                          src={content.image6}
                          isThumb={true}
                          isLazy={true}
                        />
                      </div>
                      <div className="right">
                        <p>
                          <Text string={content.sec2_right3_left_text} />
                        </p>
                      </div>
                      <div className="full-txt">
                        <Text
                          string={content.sec2_right3_left_desc}
                          parse={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="donate-cta">
                  <div className="flex">
                    <div className="_colL">
                      <div className="_image">
                        <ImageControl
                          folder="images"
                          src={content.image7}
                          isThumb={true}
                          isLazy={true}
                        />
                      </div>
                    </div>
                    <div className="_colR">
                      <div className="_inner">
                        <h3>
                          <Text string={content.sec3_subheading} />
                        </h3>
                        <Text string={content.section3_desc} parse={true} />
                        <div className="bTn">
                          <Link
                            to={content.section3_button_link}
                            className="webBtn"
                          >
                            <Text string={content.section3_button_text} />
                          </Link>
                          <Link
                            to={content.section3_button_link_right}
                            className="webBtn emptyBtn"
                          >
                            <Text string={content.section3_button_text_right} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ab_image">
                    <ImageControl
                      folder="images"
                      src={content.image8}
                      isThumb={true}
                    />
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default WhatIsHumanTraffik;

import http from "../../helpers/http";

import {
  FETCH_START_A_FUNDRAISER_CONTENT_SUCCESS,
  FETCH_START_A_FUNDRAISER_CONTENT_FAILED
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
  http
    .get("start-a-fundraiser")
    .then(({ data }) => {
      dispatch({
        type: FETCH_START_A_FUNDRAISER_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_START_A_FUNDRAISER_CONTENT_FAILED,
        payload: error
      });
    });
};

import React, { useState } from "react";
import Pagination from "./pagination";
import { format_date } from "../helpers/helpers";
import Text from "./Text";
import StaticImage from './StaticImage';

export default function EventsBlk({ items }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8);

    //get Current posts
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);
    function paginate(pageNumber) {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 3)
    }
    console.log(items)
    return <>
        <div className="flex">
            {

                currentItems !== undefined && currentItems !== '' && currentItems !== null && currentItems?.length > 0 ?

                    currentItems?.map((item, index) => {
                        return (
                            <div className="col">
                                <div className="itmBlk">
                                    <div className="ico">
                                        <img src={item?.thumbnail ? item?.thumbnail : '/images/no-image.svg'} alt={item?.title} />
                                    </div>
                                    <div className="txt">
                                        <h4>
                                            <Text string={item?.title} parse={true} />
                                        </h4>

                                        <ul className="view">
                                            <li>
                                                <div className="f_icon">
                                                    <StaticImage src="locationn.png" />
                                                </div>
                                                {item?.address}
                                            </li>
                                            <li>
                                                <div className="f_icon">
                                                    <StaticImage src="calender1.png" />
                                                </div>
                                                {item?.date?.when !== '' ? (item?.date?.when) : ''}
                                            </li>
                                            {/* <li>
                                  <div className="f_icon">
                                    <StaticImage src="clock.png" />
                                  </div>
                                  {eventTimeFormat(event.event_time)} Onwards
                                </li> */}

                                        </ul>
                                        <div className="bTn">
                                            <a href={item?.link} className="webBtn" target="_blank">View Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className='alert alert-danger'>No events(s) added yet!</div>
            }
        </div>
        <Pagination paginate={paginate} itemsPerPage={itemsPerPage} totalItems={items?.length} currentPage={currentPage} />
    </>;
}

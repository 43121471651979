import { doObjToFormData } from "../../helpers/helpers";
import http from "../../helpers/http";
import { toast } from "react-toastify";
import Text from "../../components/Text";
import { TOAST_SETTINGS } from "../../utils/siteSettings";

import {
    FETCH_PRIVACY_POLICY_CONTENT_SUCCESS,
    FETCH_PRIVACY_POLICY_CONTENT_FAILED,
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
    http
        .get("privacy-policy")
        .then(({ data }) => {
            dispatch({
                type: FETCH_PRIVACY_POLICY_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_PRIVACY_POLICY_CONTENT_FAILED,
                payload: error
            });
        });
};

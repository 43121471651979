import React, { useEffect } from 'react'
import { useParams } from "react-router-dom";
import LoadingScreen from '../../components/LoadingScreen';
import { getData } from '../../helpers/helpers';
import useDocumentTitle from '../../hooks/useDocumentTitle';
export default function Verification() {
    const { token } = useParams();
    async function verifyEmail() {
        var form_data = new FormData();
        form_data.append('email', true);
        await getData("api/auth/verification", token).then((data) => {
            console.log(data)
            if (data.status === 1) {
                window.location.replace("/profile");
            }
            else {
                window.location.replace("/member-verification");
            }
        });
    }
    useEffect(() => {
        verifyEmail()
    }, []);
    useDocumentTitle('Verification');
    return (
        <>
            <LoadingScreen isLoading={true} />
        </>
    )
}

import { isEmpty } from "lodash";
import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react'
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
export default function LeafLetMap({ markers }) {
    const [coordinates, setCoordinates] = useState([]);
    const [position, setPosition] = useState([35.6961337, -116.9615762]);

    useEffect(() => {
        if (markers?.length > 0) {
            setCoordinates(markers?.map((marker) => [marker?.latitude, marker?.longitude]));
        }

    }, [markers]);


    const boundaryStyle = {
        // fillColor: '#00acfc', // Change to the desired boundary fill color
        color: '#00acfc', // Change to the desired boundary border color
        weight: 2, // Change to the desired boundary border weight
        opacity: 1, // Change to the desired boundary border opacity
    };
    return <>

        {isEmpty(markers) ? (
            ""
        ) :
            coordinates?.length && (
                <MapContainer
                    center={position} zoom={2}
                    scrollWheelZoom={true}
                    bounds={coordinates}
                    boundsOptions={{ padding: [1, 1] }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {markers?.map((marker, index) => {
                        return (
                            <Marker position={[
                                marker?.lat,
                                marker?.lng
                            ]} key={index}>
                                <Popup className='leafMapPopup'>
                                    <div className='national_lst'>
                                        <h2>{marker.name}</h2>
                                        <p>{marker?.user_name}</p>
                                        <p>{marker?.user_email}</p>
                                        <p>{marker?.user_phone}</p>
                                        <p>
                                            {marker?.address_line1 !== undefined && marker?.address_line1 !== '' && marker?.address_line1 !== null ? marker?.address_line1 + ", " : ''} {marker.city}, {marker.zipcode}
                                        </p>
                                    </div>

                                </Popup>
                            </Marker>
                        )
                    })}
                </MapContainer>
            )}
    </>;
}

import React from "react";
import * as helpers from "../helpers/helpers";

export default function Text({ string, length, parse }) {
  string = string ?? "default";
  length = length ?? false;
  parse = parse ?? false;

  if (length)
    if (string.length > length) string = string.slice(0, length) + "...";

  if (parse) string = helpers.doParseHTML(string);

  return <div>{string}</div>;
}

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, fetchNews } from "../states/actions/home";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import {
  getBackgroundImageUrl,
  getUploadsUrl,
  newsDateFormat
} from "../helpers/helpers";
import StaticImage from "../components/StaticImage";
import Partner from "../components/Partner";
import BannerVideo from "../components/BannerVideo";
import ContentBannerVideo from "../components/ContentBannerVideo";
import useScrollTop from "../hooks/useScrollTop";
import SubscribeForm from "../components/subscribe";
const _ = require("lodash");

const HomeContainer = () => {
  const data = useSelector((state) => state.home.content);
  const isLoading = useSelector((state) => state.home.isLoading);
  const news = useSelector((state) => state.home.news);
  const dispatch = useDispatch();
  const { content, site_settings, partners } = data;
  // console.log(data);

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);


  useDocumentTitle(data.page_title);
  useScrollTop();
  return (

    <>
    
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
        
          <Header siteSettings={site_settings} />
          
          <main className="index">
            <section
              id="banner"
              className="flexBox"
              style={{

                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="flex flex_1">
                  <div className="video">
                    <ContentBannerVideo poster={content.poster} video={content.video} />
                  </div>
                  <div className="side-poster relative">
                    <div className="image">
                      <ImageControl folder="images" src={content.image2} />
                    </div>
                    <div className="cntnt">
                      <div className="immage">
                        <ImageControl folder="images" src={content.image3} />
                      </div>
                      <h2>
                        <Text string={content.banner_heading} />
                      </h2>
                      <p>
                        <Text string={content.banner_desc} />
                      </p>
                      <div className="bTn">
                        <Link
                          to={content.banner_button_link}
                          className="webBtn"
                        >
                          <Text string={content.banner_button_title} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex vid">

                  {news.slice(0, 4).map((nws, index) => (
                    <div className="col" key={index}>
                      <div className="inner">
                        <div className="image">
                          <img src={nws.urlToImage} />
                          <a href={nws.url} target="_blank"></a>
                        </div>
                        <div className="contnt">
                          <h4>
                            <a href={nws.url} target="_blank">
                              <Text string={nws.title} />
                            </a>
                          </h4>
                          <ul className="view-detail flex">
                            <li>
                              <em>{newsDateFormat(nws.publishedAt)}</em>
                            </li>
                            <li>
                              <em>
                                <Text string={"author: " + nws.author} />
                              </em>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="lives">
              <div className="contain">
                <div className="imagee">
                  <ImageControl folder="images" src={content.image4} />
                </div>
                <div className="imagee">
                  <ImageControl folder="images" src={content.image5} />
                </div>
                <div className="cntntt text-center">
                  <h1>
                    <Text string={content.sec1_heading} />
                  </h1>
                  <p>
                    <Text string={content.sec1_desc} />
                  </p>
                  <div className="bTn">
                    <Link to={content.sec1_button_link} className="webBtn">
                      <Text string={content.sec1_button_title} />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            {/* lives */}
            <section className="map">
              <div className="contain">
                <div className="text-center">
                  <h1>
                    <Text string={content.sec2_headings} />
                  </h1>
                </div>
                <div className="content text-center">
                  <h5>
                    <Text string={content.sec2_subheading} />
                  </h5>
                  <h5 className="medium sml">
                    <Text string={content.sec1_desc} />
                  </h5>
                </div>
                <div className="content text-center">
                  <h1>
                    <Text string={content.stats_headings} />
                  </h1>
                  <h5>
                    <Text string={content.stats_subheading} />
                  </h5>
                </div>
                <div className="ProgressBox">
                  <div className="progress-box relative flex">
                    <div className="blk">
                      <div className="circle_percent" data-percent={51}>
                        <div className="circle_inner">
                          <div className="round_per" />
                        </div>
                      </div>
                      <div className="inner_">
                        <div className="count_number">{content?.stat1_heading}</div>
                        <Text string={content?.stat1_desc} parse={true} />
                      </div>
                    </div>
                  </div>
                  <div className="progress-box relative flex">
                    <div className="blk">
                      <div className="circle_percent" data-percent={28}>
                        <div className="circle_inner">
                          <div className="round_per" />
                        </div>
                      </div>
                      <div className="inner_">
                        <div className="count_number">{content?.stat2_heading}</div>
                        <Text string={content?.stat2_desc} parse={true} />
                      </div>
                    </div>
                  </div>
                  <div className="progress-box relative flex">
                    <div className="blk">
                      <div className="circle_percent" data-percent={21}>
                        <div className="circle_inner">
                          <div className="round_per" />
                        </div>
                      </div>
                      <div className="inner_">
                        <div className="count_number">{content?.stat3_heading}</div>
                        <Text string={content?.stat3_desc} parse={true} />
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="medium text-center">
                  (Estimates by The United Nations Office for Drugs and Crime
                  (UNODC))
                </h5>
              </div>
            </section>
            {/* map */}
            <section className="listen_now">
              <div className="contain">
                <div className="flex">
                  <div className="colL">
                    <ImageControl folder="images" src={content.image6} />
                  </div>
                  <div className="colR">
                    <h6>
                      <Text string={content.sec3_tagline} />
                    </h6>
                    <h2>
                      <Text string={content.sec3_heading} /> <br />
                      <Text string={content.sec3_subheading} />
                    </h2>
                    <h6>
                      <Text string={content.section3_desc} />
                    </h6>
                    <p>
                      <small>Streaming Now On All Platforms</small>
                    </p>
                    <div className="flex">
                      <ImageControl
                        folder="images"
                        src={content.image7}
                        classes="lis_img"
                      />
                      <ImageControl
                        folder="images"
                        src={content.image8}
                        classes="lis_img"
                      />
                    </div>
                    <div className="playerBlk margn_top_30">
                      <div className="playerTitle">
                        <h4>
                          <Text string={content.audio_heading} />
                        </h4>
                        <h5>
                          <Text string={content.audio_tagline} />
                        </h5>
                      </div>
                      {/* <audio controls="controls">
                        <source src="track.ogg" type="audio/ogg" />
                        <source src="track.mp3" type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio> */}
                      <iframe style={{ borderRadius: "12px" }} src="https://open.spotify.com/embed/track/18geWvX0DY0RUdHfaR1Ozp?utm_source=generator" width="100%" height="240"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Listen Now */}
            <section className="ways">
              <div className="contain  text-center">
                <div className="content">
                  <h1 className="heading">
                    <Text string={content.section4_heading} />
                  </h1>
                </div>
                <div className="flexRow flex">
                  <div className="col">
                    <div className="inner">
                      <div className="icon">
                        <StaticImage src="01.png" />
                      </div>
                      <div className="txt">
                        <h4 className="heading">
                          <Text string={content.media1_heading} />
                        </h4>
                        <p>
                          <Text string={content.media1_desc} />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inner">
                      <div className="icon">
                        <StaticImage src="02.png" />
                      </div>
                      <div className="txt">
                        <h4 className="heading">
                          <Text string={content.media2_heading} />
                        </h4>
                        <p>
                          <Text string={content.media2_desc} />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inner">
                      <div className="icon">
                        <StaticImage src="03.png" />
                      </div>
                      <div className="txt">
                        <h4 className="heading">
                          <Text string={content.media3_heading} />
                        </h4>
                        <p>
                          <Text string={content.media3_desc} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* ways */}
            <section
              className="impact_counter"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image9)})`
              }}
            >
              <div className="contain  text-center">
                <div className="content">
                  <h1 className="heading">
                    <Text string={content.sec5_heading} />
                  </h1>
                </div>
                <div className="flexRow flex">
                  <div className="col">
                    <div className="inner">
                      <h1 className="heading">
                        <Text string={content.sec5_num1} />
                      </h1>
                      <h5>
                        <Text string={content.sec5_title1} />
                      </h5>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inner">
                      <h1 className="heading">
                        <Text string={content.sec5_num2} />
                      </h1>
                      <h5>
                        <Text string={content.sec5_title2} />
                      </h5>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inner">
                      <h1 className="heading">
                        <Text string={content.sec5_num3} />
                      </h1>
                      <h5>
                        <Text string={content.sec5_title3} />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* imapct counter */}
            <section className="spponsers">
              <div className="contain  text-center">
                <div className="content">
                  <h1 className="heading">
                    <Text string={content.sponsors_heading} />
                  </h1>
                </div>
                <div className="flexRow flex">
                  {partners &&
                    partners.map((partner, index) => <Partner key={index} partner={partner} />)}
                </div>
              </div>
            </section>
            {/* spponsers */}
            <section className="subbscribe">
              <div className="contain text-center">
                <div className="imagee">
                  <ImageControl folder="images" src={content.image10} />
                </div>
                <div className="imagee">
                  <ImageControl folder="images" src={content.image11} />
                </div>
                <div className="content">
                  <h1>
                    <Text string={content.subscribe_heading} />
                  </h1>
                  <p>
                    <Text string={content.subscribe_desc} />
                  </p>
                </div>
                <div className="cntntt">
                  <SubscribeForm content={content} />
                </div>
              </div>
            </section>
            {/* subbscribe */}
            <section className="danger">
              <div className="contain">
                <div className="content text-center">
                  <h1 className="heading">
                    <Text string={content.last_heading} />
                  </h1>
                  <p>
                    <Text string={content.last_desc} />
                  </p>
                </div>
                <div className="flexRow flex">
                  <div className="col">
                    <div className="inner flex">
                      <div className="icon">
                        <i className="fi-phone" />
                      </div>
                      <div className="text">
                        <Text string={content.last_card1_desc} parse={true} />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inner flex">
                      <div className="icon">
                        <i className="fi-phone" />
                      </div>
                      <div className="text">
                        <Text string={content.last_card2_desc} parse={true} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* danger */}
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default HomeContainer;

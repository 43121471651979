import {
  FETCH_DONATE_NOW_CONTENT_SUCCESS,
  FETCH_DONATE_NOW_CONTENT_FAILED,
  SAVE_DONATION,
  SAVE_DONATION_SUCCESS,
  SAVE_DONATION_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  isCompleted: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_DONATE_NOW_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload
      };
    case FETCH_DONATE_NOW_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case SAVE_DONATION:
      return {
        ...state,
        isFormProcessing: true
      };
    case SAVE_DONATION_SUCCESS:
      return {
        ...state,
        isCompleted: true,
        isFormProcessing: false
      };
    case SAVE_DONATION_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    default:
      return state;
  }
}

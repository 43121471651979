import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Image from "../ImageControl";
import Text from "../Text";
import { useSelector } from "react-redux";
const Header = () => {
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
  const member = siteSettings?.member;

  // toggle
  const [menuToggle, setMenueToggle] = useState(false);
  function toggleMenu() {
    setMenueToggle(!menuToggle);
  }
  if (isLoading) return "";
  return (
    <>
      <header className="ease">
        <div className="topNav">
          <div className="contain">
            <div className="flex">
              <div className="colL">
                <p>
                  <i className="fi-envelope" />{" "}
                  <a href={`mailto:${siteSettings.site_email}`}>
                    <Text string={siteSettings.site_email} />
                  </a>
                </p>
                <p>
                  <i className="fi-phone" />{" "}
                  <a href={`tel:${siteSettings.site_phone}`}>
                    <Text
                      string={
                        siteSettings.site_phone +
                        " / " +
                        siteSettings.site_phone_text
                      }
                    />
                  </a>
                </p>
                <p>
                  <NavLink
                    to="/contact-us"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Contact Us
                  </NavLink>
                </p>
                <p>
                  <NavLink
                    to="/news"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    News
                  </NavLink>
                </p>
                <p>
                  <NavLink
                    to="/events-near-you"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Events Near you
                  </NavLink>
                </p>
              </div>
              <div className="colR flex">
                <Link
                  to={siteSettings.site_header_donation_link}
                  className="popBtn"
                >
                  Subscribe for $20/week
                </Link>
                {member?.mem_id !== undefined &&
                  member?.mem_id !== null &&
                  member?.mem_id > 0 ? (
                  <NavLink to="/profile">Profile</NavLink>
                ) : (
                  <NavLink to="/auth/signin">Sign In</NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="contain">
          <div className="logo ease">
            <Link to="/">
              <Image folder="images" src={siteSettings.site_logo} />
            </Link>
          </div>
          <div
            className={menuToggle === true ? "toggle active" : "toggle"}
            onClick={toggleMenu}
          >
            <span />
          </div>
          <nav className={menuToggle === true ? "ease active" : "ease"}>
            <ul id="nav">
              <li>
                <NavLink
                  to="/what-is-human-trafficking"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  About Sex &amp; Trafficking
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/mission-statement"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Mission Statement
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/our-activity"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Our Activities
                </NavLink>
              </li>

              <li className="drop">
                <a >Help &amp; Resources</a>
                <ul className="sub">
                  <li>
                    <NavLink
                      to="/help-and-resources"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Help &amp; Resources
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/traffik-and-sex"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Traffik n Sex{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/national-directory"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      National Directory
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/current-affairs"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Current Affairs{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/rescue-stories"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Rescue Stories
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/share-your-stories"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Share Your Stories
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/project-unite"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Project Unite
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink
                  to="/our-sponsers"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Our Sponsors
                </NavLink>
              </li>
              <li className="drop">
                <a>How to Fight</a>
                <ul className="sub">
                  <li>
                    <NavLink
                      to="/donate-now"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Donate Now
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/corporate-partners"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Corporate Partnerships
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/start-a-fundraiser"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Start A Fundraiser
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/donate-now"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Donate Pay
                    </NavLink>
                  </li> */}
                </ul>
              </li>
            </ul>
            <ul id="cta">
              <li>
                <Link to="/donate-now" className="webBtn">
                  Donate now
                </Link>
              </li>
            </ul>
          </nav>
          <div className="clearfix" />
        </div>
      </header>
      ;
    </>
  );
};

export default Header;

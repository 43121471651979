import React from "react";
import { Link } from "react-router-dom";
import Image from "../ImageControl";
import Text from "../Text";
import StaticImage from "../StaticImage";

const Footer = ({ siteSettings }) => {
  return (
    <footer>
      <div className="contain">
        <div className="flexRow flex">
          <div className="col">
            <div className="footerLogo">
              <Link to="/">
                <Image folder="images" src={siteSettings.site_footer_logo} />
              </Link>
            </div>
            <p>
              <Text string={siteSettings.site_footer_text} />
            </p>
          </div>
          <div className="col">
            <h5>
              <Text string={siteSettings.footer_first_section_heading} />
            </h5>
            <ul className="lst">
              <li>
                <Link to="/what-is-human-trafficking">
                  About Sex And Trafficking
                </Link>
              </li>
              <li>
                <Link to="/our-sponsers">Our Sponsors</Link>
              </li>
              <li>
                <Link to="/mission-statement">Mission Statement</Link>
              </li>
              <li>
                <Link to="/our-activity">Our Activities</Link>
              </li>
              <li>
                <Link to="/donate-now">Donate Now</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h5>
              <Text string={siteSettings.footer_second_section_heading} />
            </h5>
            <ul className="lst infoLst">
              <li>
                <span>
                  <Text string={siteSettings.site_address} />
                </span>
              </li>
              <li>
                <a href={`mailto:${siteSettings.site_email}`}>
                  <Text string={siteSettings.site_email} />
                </a>
              </li>
              <li>
                <a href={`tel:${siteSettings.site_phone}`}>
                  <Text string={siteSettings.site_phone} />
                </a>
              </li>
            </ul>
            <ul className="social flex">
              {
                siteSettings.site_facebook !== '' && siteSettings.site_facebook !== null && siteSettings.site_facebook !== undefined ?

                  <li>
                    <a href={siteSettings.site_facebook} target="_blank">
                      <StaticImage src="social-facebook.svg" />
                    </a>
                    {/* <a href={`skype:${siteSettings.site_facebook}?call`}>
                  <StaticImage src="social-facebook.svg" />
                </a> */}
                  </li>
                  :
                  ""
              }
              {
                siteSettings.site_instagram !== '' && siteSettings.site_instagram !== null && siteSettings.site_instagram !== undefined ?
                  <li>
                    <a href={siteSettings.site_instagram} target="_blank">
                      <StaticImage src="social-instagram.svg" />
                    </a>
                  </li>
                  :
                  ""
              }
              {
                siteSettings.site_youtube !== '' && siteSettings.site_youtube !== null && siteSettings.site_youtube !== undefined ?
                  <li>
                    <a href={siteSettings.site_youtube} target="_blank">
                      <StaticImage src="social-youtube.svg" />
                    </a>
                    {/* <a href={`https://wa.me/${siteSettings.site_whatsapp}`}>
                  <StaticImage src="social-youtube.svg" />
                </a> */}
                  </li>
                  :
                  ""
              }
              {
                siteSettings.site_twitter !== '' && siteSettings.site_twitter !== null && siteSettings.site_twitter !== undefined ?
                  <li>
                    <a href={siteSettings.site_twitter} target="_blank">
                      <StaticImage src="social-twitter.svg" />
                    </a>
                  </li>
                  :
                  ""
              }

            </ul>
          </div>
        </div>
      </div>
      <div className="copyright relative">
        <div className="contain">
          <div className="inner">
            <p>
              <Text string={siteSettings.site_copyright} />
            </p>
            <ul className="smLst flex">
              <li>
                <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

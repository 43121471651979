import React from 'react'
import Text from '../Text';
import ImageControl from '../ImageControl';

export default function CaughtTraffic({ content, handleImpactDonation }) {
    return <>
        <section className="caught">
            <div className="contain text-center">
                <div className="content">
                    <h1 className="heading">
                        <Text string={content.section2_heading} />
                    </h1>
                    <p>
                        <Text string={content.section2_detail} />
                    </p>
                </div>
                <div className="flexRow flex">
                    <div className="col">
                        <div className="workBlk">
                            <div className="image">
                                <ImageControl
                                    folder="images"
                                    src={content.image3}
                                    isThumb={true}
                                />
                            </div>
                            <div className="txt">
                                <h4>
                                    <Text string={content.sec2_image_tagline1} />
                                </h4>
                                <a
                                    href="#!"
                                    className="webBtn popBtn"
                                    data-popup="shopTshirt"
                                    onClick={() => handleImpactDonation(content?.sec2_amount1, content?.sec2_type1)}
                                >
                                    <Text string={content.sec2_button_text1} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="workBlk">
                            <div className="image">
                                <ImageControl
                                    folder="images"
                                    src={content.image4}
                                    isThumb={true}
                                />
                            </div>
                            <div className="txt">
                                <h4>
                                    <Text string={content.sec2_image_tagline2} />
                                </h4>
                                <a
                                    href="#!"
                                    className="webBtn popBtn"
                                    data-popup="shopTshirt"
                                    onClick={() => handleImpactDonation(content?.sec2_amount2, content?.sec2_type2)}
                                >
                                    <Text string={content.sec2_button_text2} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="workBlk">
                            <div className="image">
                                <ImageControl
                                    folder="images"
                                    src={content.image5}
                                    isThumb={true}
                                />
                            </div>
                            <div className="txt">
                                <h4>
                                    <Text string={content.sec2_image_tagline3} />
                                </h4>
                                <a
                                    href="#!"
                                    className="webBtn popBtn"
                                    data-popup="shopTshirt"
                                    onClick={() => handleImpactDonation(content?.sec2_amount3, content?.sec2_type3)}
                                >
                                    <Text string={content.sec2_button_text3} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

import { doObjToFormData } from "../../helpers/helpers";
import http from "../../helpers/http";
import { toast } from "react-toastify";
import Text from "../../components/Text";
import { TOAST_SETTINGS } from "../../utils/siteSettings";

import {
  FETCH_SIGN_UP_CONTENT_SUCCESS,
  FETCH_SIGN_UP_CONTENT_FAILED,
  CREATE_ACCOUNT_MESSAGE,
  CREATE_ACCOUNT_MESSAGE_SUCCESS,
  CREATE_ACCOUNT_MESSAGE_FAILED
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
  http
    .get("sign-up")
    .then(({ data }) => {
      dispatch({
        type: FETCH_SIGN_UP_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SIGN_UP_CONTENT_FAILED,
        payload: error
      });
    });
};

export const createAccount = (formData) => (dispatch) => {
  dispatch({
    type: CREATE_ACCOUNT_MESSAGE,
    payload: null
  });
  http
    .post("auth/create-account", doObjToFormData(formData))
    .then(({ data }) => {
      console.log(data)
      if (data.validationErrors) {
        toast.error(
          <Text string={data.validationErrors} parse={true} />,
          TOAST_SETTINGS
        );

      } else {
        toast.success(
          "Account have been created successfully.",
          TOAST_SETTINGS
        );
        setTimeout(() => {
          window.location.replace("/member-verification");
        }, 1000);

      }
      dispatch({
        type: CREATE_ACCOUNT_MESSAGE_SUCCESS,
        payload: data
      });

    })
    .catch((error) => {
      dispatch({
        type: CREATE_ACCOUNT_MESSAGE_FAILED,
        payload: error
      });
    });
};

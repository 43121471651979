import React from "react";
import { Link } from "react-router-dom";
import ImageControl from "./ImageControl";
import Text from "./Text";

const SocialIconCard = ({ stories }) => {
  return (
    <>
      {stories.map((story, index) => (
        <div className="flexRow flex" key={index}>
          <div className="col col1">
            <div className="image">
              <ImageControl
                folder="rescue_stories"
                src={story.image}
                isLazy="true"
              />
            </div>
          </div>
          <div className="col col2">
            <div className="contentt">
              <h1>
                <Text string={`Case No ${story.id}`} />
              </h1>
              <ul>
                <li>
                  <span>Victim : </span> <Text string={story.victim} />
                </li>
                <li>
                  <span>Age : </span> <Text string={story.age} />
                </li>
                <li>
                  <span>Gender : </span> <Text string={story.gender} />
                </li>
                <li>
                  <span>Location :</span> <Text string={story.location} />
                </li>
                <li>
                  <span>Type of Trafficking : </span>{" "}
                  <Text string={story.types_of_trafficking} />
                </li>
              </ul>
              <Text string={story.short_description} parse={true} />
              <div className="bTn margn_top_30">
                <Link
                  to={`/rescue-stories/detail/${story.id}`}
                  className="blankBtn webBtn"
                >
                  Read Full Story
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SocialIconCard;

import React from "react";
import Text from "./Text";

const StatCard = ({ heading, detail }) => {
  return (
    <>
      <div className="col">
        <div className="inner">
          <h3>
            <Text string={heading} />
          </h3>
          <h1>
            <Text string={detail} />
          </h1>
        </div>
      </div>
    </>
  );
};

export default StatCard;

import React from "react";
import Header from "../components/includes/header";
import Subscripsection from "../components/dashboard-pages/subscrip-section";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from '../components/LoadingScreen';
const Subscription = () => {
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
  const member = siteSettings?.member;
  console.log(parseInt(member?.mem_verified))
  useDocumentTitle('Subscription');
  if (isLoading) return <LoadingScreen isLoading={isLoading} />;

  if (parseInt(member?.mem_verified) === 0) {
    return window.location.replace("/member-verification");
  }
  return (
    <>
      <style>{"body { background-color: #121b22; }"}</style>
      <Header siteSettings={siteSettings} />
      <Subscripsection member={member} siteSettings={siteSettings} />
    </>
  );
};

export default Subscription;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { doObjToFormData, imageValidation, postData, projectImageUpload } from "../helpers/helpers";
import { TOAST_SETTINGS } from "../utils/siteSettings";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from '@hookform/error-message';
import ImageControl from "./ImageControl";
import { NavLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
export default function ShareStoriesForm() {
    const [storyState, setStoryState] = useState(false);
    const [thumbnailState, setThumbnail] = useState({
        loading: false,
        image: null,
    });
    let thumbnail = null;
    function uploadThumbnail() {
        thumbnail.click();
    }
    async function handleUploadThumbnail(e) {
        setThumbnail({ loading: 'loading.gif' });
        const files = e.target.files[0];
        let valid = imageValidation(files);
        if (valid.error) {
            toast.error(valid.error,
                TOAST_SETTINGS
            );
        }
        else {
            let image = await projectImageUpload(files);
            if (image.status === 1) {
                setThumbnail({ loading: false, image: image.image, image_name: image.image });
            }
            else {
                toast.error(image.msg,
                    TOAST_SETTINGS
                );
            }
        }
    };

    const { register, handleSubmit, watch, control, formState: { errors } } = useForm({ mode: 'onChange' });
    const onSubmit = async form_data => {
        setStoryState(true);
        if (thumbnailState?.image !== null) {
            let new_frm_data = { ...form_data, image: thumbnailState?.image }
            await postData("api/auth/save-share-story", doObjToFormData(new_frm_data)).then((data) => {
                console.log(data)
                setStoryState(false);
                if (data.status === 1) {
                    toast.success(data.msg,
                        TOAST_SETTINGS
                    );
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                }
                else {
                    toast.error(data.msg,
                        TOAST_SETTINGS
                    );
                }
            });
        }
        else {
            toast.error('Please upload File first!',
                TOAST_SETTINGS
            );
        }
    }
    return <>
        <ToastContainer />
        <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
            <h4 className="heading">Tell Us About You.</h4>
            <div className="row formRow">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="Name"
                            {...register("name", {
                                pattern: {
                                    value: /^[a-zA-Z][a-zA-Z ]*$/,
                                    message: 'Invalid value',
                                },
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="Email id"
                            {...register("email", {
                                required: "Required", pattern: {
                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                    message: "Email format is not valid!"
                                }
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                    <div className="txtGrp">
                        <select className="txtBox"  {...register("survivor", {
                            required: "Required"
                        })}>
                            <option value="">Survivor</option>
                            <option value="Survivor 1">Survivor 1</option>
                            <option value="Survivor 2">Survivor 2</option>
                        </select>
                        <ErrorMessage
                            errors={errors}
                            name="survivor"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="If Other"
                            {...register("survivor_name")}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="Story Title"
                            {...register("story_title", {
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="story_title"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    <div className="txtGrp">
                        <textarea
                            name
                            id
                            className="txtBox"
                            placeholder="Story Summary"
                            {...register("story_summary", {
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="story_summary"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="Comments(Optional)"
                            {...register("comments", {
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="comments"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    <div className="info_photo">
                        <p>
                            For best results, photos must be at least 700px
                            wide and 600px high. Photos must be in one of the
                            following formats: .jpg, .jpeg, .png, .gif, and
                            5Mb or less.
                        </p>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    {
                        thumbnailState?.loading !== false ?

                            <div className="share-image">
                                <img src={thumbnailState?.loading} />
                            </div>
                            :
                            thumbnailState?.loading === false && thumbnailState?.image !== null ?
                                <div className="share-image">
                                    <ImageControl folder="images" src={thumbnailState?.image} />
                                </div>
                                :
                                ""
                    }
                    <div className="bTn">
                        <a href="#!" className="blankBtn webBtn" onClick={uploadThumbnail}>
                            Choose File
                        </a>
                        {/* <p>No File Selected </p> */}
                    </div>
                    <input type="file" name="" id="" className="uploadFile" data-upload="gallery_image"


                        onChange={handleUploadThumbnail}
                        accept="image/*"
                        ref={(file) => (thumbnail = file)} />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    <div className="bTn">
                        <input type="checkbox" {...register("confirm", { required: "Required" })} />
                        <p>
                            By submitting your story you agree to the <NavLink to="/terms-and-conditions">Terms of
                                Use</NavLink>, including the terms for posted content, and
                            our <NavLink to="/privacy-policy">Privacy Policy</NavLink>.
                        </p>
                    </div>
                    <ErrorMessage
                        errors={errors}
                        name="confirm"
                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                    />
                </div>
            </div>
            <div className="bTn formBtn">
                <button type="submit" className="webBtn lgBtn" disabled={(storyState === true && thumbnailState?.loading === false) ? 'disabled' : ''}>
                    Submit My Story  {(storyState === true && thumbnailState?.loading === false) ? <><i className="spinner"></i></> : ''}
                </button>
            </div>
        </form>
    </>;
}

import React, { useState } from "react";
import Text from './Text';
import { useForm } from "react-hook-form";
import { doObjToFormData, postData } from "../helpers/helpers";
import { TOAST_SETTINGS } from "../utils/siteSettings";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from '@hookform/error-message';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
export default function FundRaiseForm({ content }) {
    const [fundraiseState, setFundraiseState] = useState(false);
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm({ mode: 'onChange' });
    const onSubmit = async form_data => {
        setFundraiseState(true);
        await postData("api/auth/save-fundraise-request", doObjToFormData(form_data)).then((data) => {
            // console.log(data)
            setFundraiseState(false);
            if (data.status === 1) {
                toast.success(data.msg,
                    TOAST_SETTINGS
                );
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }
            else {
                toast.error(data.msg,
                    TOAST_SETTINGS
                );
            }
        });
    }
    return <>
        <ToastContainer />
        <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
            <h1>
                <Text string={content.form_heading} parse={true} />
            </h1>
            <div className="sub_heading heading">
                <Text string={content.form_tagline} parse={true} />
            </div>
            <div className="row formRow">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="Display Name"
                            {...register("name", {
                                pattern: {
                                    value: /^[a-zA-Z][a-zA-Z ]*$/,
                                    message: 'Invalid value',
                                },
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="FUNDRAISER EMAIL"
                            {...register("email", {
                                required: "Required", pattern: {
                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                    message: "Email format is not valid!"
                                }
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    <div className="txtGrp">
                        <select className="txtBox" {...register("goal", {
                            required: "Required"
                        })}>
                            <option value="">FUNDRAISING GOAL</option>
                            <option value="$5000-$6000">$5000-$6000</option>
                            <option value="$5000-$6000">$6000-$7000</option>
                        </select>
                        <ErrorMessage
                            errors={errors}
                            name="goal"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    <div className="txtGrp">
                        <Controller
                            control={control}
                            name="goal_date"
                            // defaultValue=""

                            {...register("goal_date", { required: "Required" })}
                            render={({ field }) => (
                                <DatePicker
                                    className="txtBox"
                                    placeholderText="_ _ / _ _ / _ _ _ _"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    minDate={new Date()}
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="goal_date"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    <div className="txtGrp">
                        <textarea
                            name
                            id
                            className="txtBox"
                            placeholder="Your Message"
                            {...register("msg", {
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="msg"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
            </div>
            <div className="bTn formBtn">
                <button type="submit" className="webBtn lgBtn" disabled={(fundraiseState === true) ? 'disabled' : ''}>
                    <Text
                        string={content.form_button_text}
                        parse={true}
                    /> {(fundraiseState === true) ? <><i className="spinner"></i></> : ''}
                </button>
            </div>
        </form>
    </>
}

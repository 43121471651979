import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, signin, googleSignin } from "../../states/actions/signIn";
import { Link, NavLink } from "react-router-dom";
import StaticImage from "../../components/StaticImage";
import LoadingScreen from "../../components/LoadingScreen";
import ImageControl from "../../components/ImageControl";
import FormProcessingSpinner from "../../components/FormProcessingSpinner";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import Text from "../../components/Text";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { getBackgroundImageUrlThumb } from "../../helpers/helpers";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

const SignInContainer = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();

  const data = useSelector((state) => state.signIn.content);
  const isLoading = useSelector((state) => state.signIn.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.signIn.isFormProcessing
  );
  const formValues = useSelector((state) => state.signIn.formData);
  const { content, site_settings } = data;

  const [formData, setFormData] = useState(formValues);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(fetchData());
  }, []);

  const onSubmit = (data) => {
    // console.log(data); return;
    dispatch(signin(data));
  };

  const successCredentialResponse = ({ credential }) => {
    console.log(jwt_decode(credential));
    dispatch(googleSignin(jwt_decode(credential)));
  };

  const errorCredentialResponse = (error) => {
    console.log(error);
  };

  useDocumentTitle(data.page_title);
  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <ToastContainer />
          <main>
            <section className="form-blk">
              <div className="flex">
                <div
                  className="colL"
                  style={{
                    backgroundImage: `url(${getBackgroundImageUrlThumb(
                      content.image1,
                      500
                    )})`
                  }}
                >
                  <div className="image">
                    <Link to="/">
                      <ImageControl
                        folder="images"
                        src={site_settings.site_logo}
                      />
                    </Link>
                    <h1 className="bold margn_top_30">
                      <Text string={content.heading} />
                    </h1>
                    <h5 className="regular">
                      <Text string={content.detail} />
                    </h5>
                  </div>
                </div>
                <div className="colR">
                  <div className="inner">
                    <h2>Login</h2>
                    {/* <div className="bTn">
                      <GoogleLogin
                        onSuccess={successCredentialResponse}
                        onError={errorCredentialResponse}
                      />
                      <a className="webBtn fbBtn" href="?">
                        <StaticImage src="ficon.png" />
                        Sign In Facebook
                      </a>
                    </div>
                    <div className="oRLine">
                      <span>Or</span>
                    </div> */}
                    <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                      <div className="row formRow">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <div className="txtGrp">
                            <h5>Email</h5>
                            <h5>
                              <input
                                type="text"
                                name="email"
                                // value={formData.email}
                                className="txtBox"
                                placeholder="Enter your email"
                                {...register("email", {
                                  required: "Required", pattern: {
                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                    message: "Email format is not valid!"
                                  }
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                              />
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <div className="txtGrp">
                            <h5>Password</h5>
                            <h5>
                              <input
                                type="password"
                                name="password"
                                // value={formData.password}
                                className="txtBox"
                                placeholder="Enter your password"
                                {...register("password", {
                                  required: "Required"
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="password"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                              />
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <NavLink to="/auth/forget-password" className="semi">
                            Forgot Password?
                          </NavLink>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <button
                            type="submit"
                            className="webBtn lgBtn"
                            disabled={isFormProcessing ? true : false}
                          >
                            Login
                            <FormProcessingSpinner
                              isFormProcessing={isFormProcessing}
                            />
                          </button>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <div className="haveAccount margn_top_30">
                            <span>Don’t have an account ?</span>
                            <Link
                              to="/auth/signup"
                              className="semi"
                              id="member"
                            >
                              Signup
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      )}
    </>
  );
};
export default SignInContainer;

import http from "../../helpers/http";
import { doObjToFormData } from "../../helpers/helpers";

import {
  FETCH_NATIONAL_DIRECTORY_CONTENT_SUCCESS,
  FETCH_NATIONAL_DIRECTORY_CONTENT_FAILED,
  SEARCH_ORGANIZATIONS,
  SEARCH_ORGANIZATIONS_SUCCESS,
  SEARCH_ORGANIZATIONS_FAILED
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
  http
    .get("national-directory")
    .then(({ data }) => {
      dispatch({
        type: FETCH_NATIONAL_DIRECTORY_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_NATIONAL_DIRECTORY_CONTENT_FAILED,
        payload: error
      });
    });
};

export const search = (formData) => (dispatch) => {
  dispatch({
    type: SEARCH_ORGANIZATIONS,
    payload: null
  });
  http
    .post("search-organizations", doObjToFormData(formData))
    .then(({ data }) => {
      dispatch({
        type: SEARCH_ORGANIZATIONS_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: SEARCH_ORGANIZATIONS_FAILED,
        payload: error
      });
    });
};

export const clearSearch = () => (dispatch) => {
  http
    .get("clear-organizations-search")
    .then(({ data }) => {
      dispatch({
        type: SEARCH_ORGANIZATIONS_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: SEARCH_ORGANIZATIONS_FAILED,
        payload: error
      });
    });
};

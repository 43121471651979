import React from "react";
import ImageControl from "./ImageControl";

const SocialIconCard = ({ src, link }) => {
  return (
    <>
      <div className="col">
        <a href={link} target="_blank" className="inner">
          <div className="_ico">
            <ImageControl folder="images" src={src} />
          </div>
        </a>
      </div>
    </>
  );
};

export default SocialIconCard;

import React from 'react'
import Text from '../Text';
export default function EnormousImpact({ content, handleImpactDonation }) {
    return <>
        <section className="impactt">
            <div className="contain text-center">
                <div className="content">
                    <h1>
                        <Text string={content.section3_heading} />
                    </h1>
                    <p className="white-color">
                        <Text string={content.section3_detail} />
                    </p>
                </div>
                <div className="flex">
                    <div className="col">
                        <div className="inner">
                            <h3>
                                <Text string={content.sec3_heading1} />
                            </h3>
                            <p className="white-color">
                                <Text string={content.sec3_desc1} />
                            </p>
                            <div className="bTn margn_top_30">
                                <a href="#!" className="webBtn" onClick={() => handleImpactDonation(content?.sec3_amount1, content?.sec3_type1)}>
                                    <Text string={content.sec3_button_text1} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="inner">
                            <h3>
                                <Text string={content.sec3_heading2} />
                            </h3>
                            <p className="white-color">
                                <Text string={content.sec3_desc2} />
                            </p>
                            <div className="bTn margn_top_30">
                                <a href="#!" className="webBtn" onClick={() => handleImpactDonation(content?.sec3_amount2, content?.sec3_type2)}>
                                    <Text string={content.sec3_button_text2} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="inner">
                            <h3>
                                <Text string={content.sec3_heading3} />
                            </h3>
                            <p className="white-color">
                                <Text string={content.sec3_desc3} />
                            </p>
                            <div className="bTn margn_top_30">
                                <a href="#!" className="webBtn" onClick={() => handleImpactDonation(content?.sec3_amount3, content?.sec3_type3)}>
                                    <Text string={content.sec3_button_text3} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

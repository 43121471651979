import {
  FETCH_SIGN_IN_CONTENT_SUCCESS,
  FETCH_SIGN_IN_CONTENT_FAILED,
  SIGN_IN_MESSAGE,
  SIGN_IN_MESSAGE_SUCCESS,
  SIGN_IN_MESSAGE_FAILED
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  formData: {
    email: "",
    password: ""
  }
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SIGN_IN_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload
      };
    case FETCH_SIGN_IN_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case SIGN_IN_MESSAGE:
      return {
        ...state,
        isFormProcessing: true
      };
    case SIGN_IN_MESSAGE_SUCCESS:
      localStorage.setItem("authToken", payload.authToken);
      return {
        ...state,
        formData: {
          email: "",
          password: ""
        },
        isFormProcessing: false
      };
    case SIGN_IN_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    default:
      return state;
  }
}

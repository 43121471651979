import React, { useState } from 'react'
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { toast, ToastContainer } from "react-toastify";
import { getData } from '../../helpers/helpers';
import { NavLink } from 'react-router-dom';
import Text from '../Text';
import 'react-toastify/dist/ReactToastify.css';
export default function SubscriptionBlk({ siteSettings, setPopup, subscription }) {
    let plans = siteSettings?.plans;
    const [cancelMembership, setCancelMembership] = useState(false);
    const [updatePlanPopup, setUpdatePlanPopup] = useState({
        item: null,
        show: false
    });
    const [updatePlan, setUpdatePlan] = useState(false);
    async function cancelSubscription() {
        setCancelMembership(true)
        await getData("api/auth/cancel-subscription").then((data) => {
            console.log(data)
            setCancelMembership(false)
            if (data.status === 1) {
                toast.success(data.msg,
                    TOAST_SETTINGS
                );
                setTimeout(() => {
                    window.location.replace("/subscription");
                }, 2000);
            }
            else {
                toast.error(data.msg,
                    TOAST_SETTINGS
                );
            }
        });
    }
    async function updateSubscription(plan) {
        setUpdatePlan(true)
        await getData("api/auth/update-subscription/" + plan?.id).then((data) => {
            console.log(data)
            setUpdatePlan(false)
            if (data.status === 1) {
                toast.success(data.msg,
                    TOAST_SETTINGS
                );
                setTimeout(() => {
                    window.location.replace("/subscription");
                }, 2000);
            }
            else {
                toast.error(data.msg,
                    TOAST_SETTINGS
                );
            }
        });
    }
    return (
        <>
            <ToastContainer />
            <form action="" method="post">
                <div className="row formRow">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12 margn_top_20">
                        <div className="bTn SdonateBtn">
                            {
                                plans !== undefined && plans !== null && plans?.length > 0 ?
                                    plans?.map((plan, index) => {
                                        return (
                                            <div className="block" key={index}>
                                                <p>{plan?.plan_type} plan</p>
                                                {
                                                    subscription?.id > 0 && subscription?.status == 1 && subscription?.subscription_status == 'paid' && subscription?.plan_id !== plan?.id ?
                                                        <button type="button" className='donate_btn_active' onClick={() => setUpdatePlanPopup({ item: plan, show: true })}> Update ${plan?.plan_price} {plan?.plan_name}</button>
                                                        :
                                                        <button type="button" className={parseInt(subscription?.plan_id) === parseInt(plan?.id) ? "donate_btn_active active" : "donate_btn_active"} onClick={() => setPopup(plan)} disabled={cancelMembership === true && parseInt(subscription?.plan_id) === parseInt(plan?.id) ? true : false}>
                                                            ${plan?.plan_price} {plan?.plan_name} {parseInt(subscription?.plan_id) === parseInt(plan?.id) && cancelMembership === true ? <i className='spinner'></i> : ""}
                                                        </button>
                                                }
                                                {
                                                    subscription !== undefined && subscription?.id > 0 ?
                                                        parseInt(subscription?.plan_id) === parseInt(plan?.id) ?
                                                            <>
                                                                <p>Yours are at {plan?.plan_type} plan</p>
                                                                <p className="redu member" onClick={() => { if (window.confirm('Are you sure you wish to cancel subscription?')) cancelSubscription() }} >Cancel Membership </p>
                                                            </>
                                                            :
                                                            // <p className="redu" onClick={() => updateSubscription(plan)}>Update</p>
                                                            ""
                                                        :
                                                        ""
                                                }


                                            </div>
                                        )
                                    })
                                    :
                                    ""
                            }

                        </div>
                        {
                            plans !== undefined && plans !== null && plans?.length > 0 ?
                                <div className="flex plan_flex">
                                    {
                                        plans?.map((plan, index) => {
                                            return (

                                                <div className="sub-list">
                                                    <h5>{plan?.plan_type} Subscription includes</h5>
                                                    <Text string={plan?.details} parse={true} />
                                                </div>
                                            )
                                        })}
                                </div>
                                :
                                ""
                        }
                    </div>
                </div>
            </form>

            {
                updatePlanPopup?.show == true && updatePlanPopup?.item?.id > 0 ?
                    <div index className="popup small-popup">
                        <div className="tableDv">
                            <div className="tableCell">
                                <div className='_inner'>
                                    <ToastContainer />
                                    <div className="crosBtn" onClick={() => setUpdatePlanPopup({ show: false, item: null })}></div>
                                    <h4>Are you sure you want to update this plan?</h4>
                                    <div className='bTn text-center'>
                                        <NavLink to="#!" className="webBtn" onClick={() => updateSubscription(updatePlanPopup?.item)} disabled={updatePlan}>Yes  {updatePlan ? <i className='spinner'></i> : ""}</NavLink>
                                        <NavLink to="#!" className="webBtn" onClick={() => setUpdatePlanPopup({ show: false, item: null })}>No</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
        </>
    )
}

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import * as paths from "../constants/paths";

const ImageControl = ({ src, folder, isThumb, isLazy, classes, alt = "Error While Loading Image" }) => {
  let url = paths.API_UPLOADS_URL; //temp
  
  isThumb = isThumb ?? false;
  isLazy = isLazy ?? false;
  src = isThumb ? `${url}${folder}/thumb_${src}` : `${url}${folder}/${src}`;
  return (
    <>
      {isLazy ? (
        <LazyLoadImage
          alt={alt}
          effect="blur"
          src={src}
          className={classes && classes}
        />
      ) : (
        <img
          alt={alt}
          src={src}
          className={classes && classes}
        />
      )}
    </>
  );
};

export default ImageControl;

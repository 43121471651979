import React, { useState, useEffect } from 'react'
import Text from '../Text';
import ImageControl from '../ImageControl';
import { toast, ToastContainer } from "react-toastify";
import PayPal from './paypal';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import 'react-toastify/dist/ReactToastify.css';
import { format_date } from '../../helpers/helpers';
export default function DonateFormSection({ content, handleDonation, isCompleted, donateAmount, setDonateType, donateType }) {
  const { register, trigger, handleSubmit, setValue, control, watch, formState: { errors, isValid } } = useForm({ mode: 'all' });
  const watchAllFields = watch();

  const [popup, setPopup] = useState(false);

  const [donation_amount, setDonateAmount] = useState(50);
  const handleDonateType = (type) => {
    setDonateType(type)
  }
  useEffect(() => {
    setDonateAmount(donateAmount)
    setValue('donation_amount', donateAmount)
  }, [donateAmount]);
  const handleGift = (type) => {
    setValue("gift", type);
    if (type === 'yes') {
      setPopup(true)
    }
  }
  const handleSize = (type) => {
    setValue("size", type);
  }

  const handleDonateAmount = (amount) => {
    setDonateAmount(amount)
  }
  const onSubmit = formData => {
    console.log(formData)
  }
  const getPaypalObject = order => {
    if (order.status === "COMPLETED") {
      let data = watchAllFields;
      let newData = { ...data, order_id: order.id, payer_id: order.payer.payer_id, type: donateType, amount: donation_amount };
      console.log(newData)
      handleDonation(newData)
    }
    else {
      console.log(order);
    }
  };
  const cancelPaypalOrder = data => {
    toast.error('You canceled the payment with PayPal.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  const errorPaypalOrder = data => {
    toast.error('There is a technical problem. Try again later.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return <>
    <section className="donate_now-sec">
      {/* <ToastContainer /> */}
      <div className="contain">
        <div className="donate_now-flex flex">
          <div className="colL">
            <div className="image">
              <ImageControl
                folder="images"
                src={content.image2}
                islazy={true}
              />
            </div>
          </div>
          <div className="colR">
            <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
              <h1 className="white-color">
                <Text string={content.banner_heading} />
              </h1>
              <h4 className="sub_heading white-color">
                <Text string={content.banner_short_dec} />
              </h4>
              <div className="row formRow" style={{marginTop : '110px'}}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                  <h5>How often would you like to donate?</h5>
                  <div className="bTn donateBtn" >
                    <button type="button" className={donateType === 'one time' ? "donate_btn donate_btn_active lgBtn" : "donate_btn lgBtn"} onClick={() => handleDonateType('one time')}>
                      One Time
                    </button>
                    <button
                      type="button"
                      className={donateType === 'Monthly' ? "donate_btn donate_btn_active lgBtn" : "donate_btn lgBtn"} onClick={() => handleDonateType('Monthly')}
                    >
                      Monthly
                    </button>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12 margn_top_30">
                  <h5>Please select your donation amount</h5>
                  <div className="bTn donateBtn">
                    <button type="button" className={parseInt(donation_amount) === 50 ? "donate_btn donate_btn_active" : "donate_btn"} onClick={() => handleDonateAmount(50)}>
                      $50
                    </button>
                    <button type="button" className={parseInt(donation_amount) === 100 ? "donate_btn donate_btn_active" : "donate_btn"} onClick={() => handleDonateAmount(100)}>
                      $100
                    </button>
                    <button
                      type="button"
                      className={parseInt(donation_amount) === 200 ? "donate_btn donate_btn_active" : "donate_btn"} onClick={() => handleDonateAmount(200)}
                    >
                      $200
                    </button>
                    <button type="button" className={parseInt(donation_amount) === 400 ? "donate_btn donate_btn_active" : "donate_btn"} onClick={() => handleDonateAmount(400)}>
                      $400
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                  <div className="txtGrp">
                    <input
                      type="text"
                      name="donation_amount"
                      id="donation_amount"
                      {...register("donation_amount", {

                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'Invalid value',
                        },
                        min: {
                          value: 0,
                          message: "Amount must be greater than 0."
                        }


                      })}
                      onChange={(e) => handleDonateAmount(e.target.value)}
                      className="txtBox"
                      placeholder="Or  enter Specific amount"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="donation_amount"
                      render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                    />
                  </div>
                </div>
                <div className='clearfix' style={{ width: "50%" }}></div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                  <div className="txtGrp">
                    <input
                      type="text"
                      name="fname"
                      id="fname"
                      {...register("fname", {
                        pattern: {
                          value: /^[a-zA-Z][a-zA-Z ]*$/,
                          message: 'Invalid value',
                        },
                        required: "Required"
                      })}
                      className="txtBox"
                      placeholder="First Name"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="fname"
                      render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                  <div className="txtGrp">
                    <input
                      type="text"
                      name="lname"
                      id="lname"
                      {...register("lname", {
                        pattern: {
                          value: /^[a-zA-Z][a-zA-Z ]*$/,
                          message: 'Invalid value',
                        },
                        required: "Required"
                      })}
                      className="txtBox"
                      placeholder="Last Name"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="lname"
                      render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                  <div className="txtGrp">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      {...register("email", {
                        required: "Required", pattern: {
                          value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                          message: "Email format is not valid!"
                        }
                      })}
                      className="txtBox"
                      placeholder="Email"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                  <div className="txtGrp">
                    <textarea name='address' id='address' className='txtBox' placeholder='Address' {...register("address", {
                      required: "Required"
                    })}></textarea>
                    <ErrorMessage
                      errors={errors}
                      name="address"
                      render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                  <h5>Do you want to receive a gift?</h5>
                  <div className="bTn donateBtn">
                    <button type="button" className={watchAllFields?.gift === 'no' ? "donate_btn donate_btn_active lgBtn" : "donate_btn lgBtn"} onClick={() => handleGift('no')} {...register("gift", {
                      required: "Required"
                    })}>
                      No
                    </button>
                    <button
                      type="button"
                      className={watchAllFields?.gift === 'yes' ? "donate_btn donate_btn_active lgBtn" : "donate_btn lgBtn"} onClick={() => handleGift('yes')}
                      {...register("gift", {
                        required: "Required"
                      })}
                    >
                      Yes
                    </button>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="gift"
                    render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">

                  {

                    donateType === 'Monthly' ?
                      <p>
                        You will be charged a reoccurring amount of ${donation_amount} on
                        the {(new Date().getDate())}th of every month which will be deducted from
                        the card that you provided.
                      </p>
                      :
                      ""
                  }
                </div>
              </div>
              <div className="bTn formBtn ">

                {
                  !isValid ?
                    <button type="button"
                      className="webBtn lgBtn" onClick={() => {
                        trigger();
                      }} >Pay &amp; Send Rescue</button>
                    :
                    // <button type="submit"
                    //   className="webBtn lgBtn">Pay &amp; Send Rescue</button>
                    <PayPal price={parseFloat(donation_amount)} getPaypalObject={getPaypalObject} cancelPaypalOrder={cancelPaypalOrder} errorPaypalOrder={errorPaypalOrder} description="Donation" />
                }

              </div>
              {
                watchAllFields?.gift === 'yes' && popup === true ?
                  <section className="popup giftPopup" data-popup="video_player" style={{ display: "block" }}>
                    <div className="tableDv">
                      <div className="tableCell">
                        <div className="_inner">
                          <div className='crosBtn' onClick={() => setPopup(false)}></div>
                          <div className='text-center'>
                            <h4>{content?.gift_heading}</h4>
                            <Text string={content.gift_desc} parse={true} />
                          </div>
                          <div className='giftBlk'>
                            <div className='colL'>
                              <div className='image'>
                                <ImageControl
                                  folder="images"
                                  src={content.image7}
                                  islazy={true}
                                />
                              </div>
                            </div>
                            <div className='colR'>
                              <div className='gift_content'>
                                <div className='cntnt'>
                                  <h4>Traffik & Sex</h4>
                                  <p>Black Solid Round Neck T-Shirt</p>
                                </div>
                                <div className='row formRow'>
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                                    <h5>Select Size</h5>
                                    <div className="bTn donateBtn">
                                      <button type="button" className={watchAllFields?.size === 'small' ? "donate_btn donate_btn_active lgBtn" : "donate_btn lgBtn"} onClick={() => handleSize('small')} {...register("size", {
                                        required: "Required"
                                      })}>
                                        S
                                      </button>
                                      <button type="button" className={watchAllFields?.size === 'medium' ? "donate_btn donate_btn_active lgBtn" : "donate_btn lgBtn"} onClick={() => handleSize('medium')} {...register("size", {
                                        required: "Required"
                                      })}>
                                        M
                                      </button>
                                      <button type="button" className={watchAllFields?.size === 'large' ? "donate_btn donate_btn_active lgBtn" : "donate_btn lgBtn"} onClick={() => handleSize('large')} {...register("size", {
                                        required: "Required"
                                      })}>
                                        L
                                      </button>
                                      <button type="button" className={watchAllFields?.size === 'XL' ? "donate_btn donate_btn_active lgBtn" : "donate_btn lgBtn"} onClick={() => handleSize('XL')} {...register("size", {
                                        required: "Required"
                                      })}>
                                        XL
                                      </button>
                                      <button type="button" className={watchAllFields?.size === 'XXL' ? "donate_btn donate_btn_active lgBtn" : "donate_btn lgBtn"} onClick={() => handleSize('XXL')} {...register("size", {
                                        required: "Required"
                                      })}>
                                        XXL
                                      </button>

                                    </div>
                                    <ErrorMessage
                                      errors={errors}
                                      name="size"
                                      render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                    />
                                  </div>
                                  <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12'>
                                    <div className="bTn formBtn ">
                                      <button type="button"
                                        className="webBtn lgBtn" onClick={() => setPopup(false)}>Continue</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  :
                  ""
              }
            </form>
          </div>
        </div>
      </div>
    </section>
  </>
}

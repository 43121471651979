
import http from "../../helpers/http";

import {
    FETCH_NEWS_CONTENT_SUCCESS,
    FETCH_NEWS_CONTENT_FAILED,
} from "./actionTypes";

export const fetchGoogleNews = () => (dispatch) => {
    http
        .get("google-news")
        .then(({ data }) => {
            dispatch({
                type: FETCH_NEWS_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_NEWS_CONTENT_FAILED,
                payload: error
            });
        });
};



import React from "react";

const Updatedsuccessfully = ({ setUpdatePopup }) => {
  return (
    <>
      <section className="cmnpopup popup">
        <div className="tableDv">
          <div className="tableCell">
            <div className="contain">
              <div className="inner text-center">
                <div
                  className="crosBtn"
                  onClick={() => setUpdatePopup(false)}
                ></div>

                <div className="txt">
                  <div className="icon check_icon">
                    <img src="images/check.svg" alt="" />
                  </div>
                  <h2>Successfully Updated</h2>
                  {/* <p>Your New Email has been updated</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// #endregion

export default Updatedsuccessfully;

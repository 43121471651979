import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchGoogleNews } from "../states/actions/fetchGoogleNews";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useScrollTop from "../hooks/useScrollTop";
import NewsBlk from "../components/newsBlk";

export default function GoogleNews() {
    const data = useSelector((state) => state.fetchGoogleNews.content);
    const isLoading = useSelector((state) => state.fetchGoogleNews.isLoading);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchGoogleNews());
        // dispatch(fetchNews());
    }, []);
    const { site_settings, news } = data;
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isSiteLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
    const member = siteSettings?.member;

    useDocumentTitle(data.page_title);
    useScrollTop();
    if (parseInt(member?.mem_verified) === 0) {
        return window.location.replace("/member-verification");
    }
    return <>
        <LoadingScreen isLoading={isLoading || isSiteLoading} />
        {!isLoading && (
            <div>
                <Header siteSettings={site_settings} />
                {
                    member?.subscription !== undefined && member?.subscription !== null && member?.subscription?.id > 0 ?
                        <main className="index">
                            <div className="mini-label">
                                <div className="contain">
                                    <ul>
                                        <li>
                                            <Link to="/">
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/google-news">
                                                News
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <section id="google_news">
                                <div className="contain">
                                    <div className="_content">
                                        <h2>Latest News</h2>
                                    </div>

                                    <NewsBlk items={news} />
                                </div>
                            </section>
                        </main>
                        :
                        <main className="liveMain subscribe_upgrade">
                            <section className="innerBox">
                                <h3>Subscribe Now</h3>
                                <p>If you want to read news you please subscribe Now.</p>
                                <div className="bTn text-center">
                                    <NavLink to="/subscription" className="webBtn colorBtn">Subscription Plans</NavLink>
                                </div>
                            </section>
                        </main>
                }
            </div>
        )};
    </>;
}

import { doObjToFormData } from "../../helpers/helpers";
import http from "../../helpers/http";
import { toast } from "react-toastify";
import Text from "../../components/Text";
import { TOAST_SETTINGS } from "../../utils/siteSettings";

import {
  FETCH_DONATE_NOW_CONTENT_SUCCESS,
  FETCH_DONATE_NOW_CONTENT_FAILED,
  SAVE_DONATION,
  SAVE_DONATION_SUCCESS,
  SAVE_DONATION_FAILED
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
  http
    .get("donate-now")
    .then(({ data }) => {
      dispatch({
        type: FETCH_DONATE_NOW_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_DONATE_NOW_CONTENT_FAILED,
        payload: error
      });
    });
};
export const saveDonation = (formData) => (dispatch) => {
  dispatch({
    type: SAVE_DONATION,
    payload: null
  });
  http
    .post("save-donation", doObjToFormData(formData))
    .then(({ data }) => {
      console.log(data)
      if (data.validationErrors) {
        toast.error(
          <Text string={data.validationErrors} parse={true} />,
          TOAST_SETTINGS
        );
      } else {
        toast.success("Donation sent successfully.", TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/donate-now");
        }, 2000);
      }

      dispatch({
        type: SAVE_DONATION_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: SAVE_DONATION_FAILED,
        payload: error
      });
    });
};

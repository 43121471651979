import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

const SideBar = () => {
  const location = useLocation();
  const Logout = () => {
    localStorage.clear();
    window.location.href = "/auth/signin";
  };
  return (
    <>
      <div className="tabs-lst">
        <ul>
          <li className={location.pathname === "/profile" ? "active" : ""}>
            <Link to="/profile">Profile</Link>
          </li>
          <li className={location.pathname === "/subscription" ? "active" : ""}>
            <Link to="/subscription">Subscription</Link>
          </li>
          {/* <li className={location.pathname === "/payment-info" ? "active" : ""}>
            <Link to="/payment-info">Payment Info</Link>
          </li> */}
          {/* <li className={location.pathname === "/live-tv" ? "active" : ""}>
            <Link to="/live-tv">Live Streaming</Link>
          </li> */}
          <li>
            <Link to="#!" onClick={Logout}>Logout</Link>
          </li>
        </ul>
      </div>
    </>
  );
};
export default SideBar;

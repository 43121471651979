import React, { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/helpAndRecources";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import useScrollTop from "../hooks/useScrollTop";

const HelpAndResourcesContainer = () => {
  const data = useSelector((state) => state.helpAndRecources.content);
  const isLoading = useSelector((state) => state.helpAndRecources.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  useDocumentTitle(data.page_title);
  useScrollTop();

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index">
            <div className="mini-label">
              <div className="contain">
                <ul>
                  <li>
                    <Link to="/help-and-resources">Help &amp; Resources</Link>
                  </li>
                  <li>
                    <Link to="/help-and-resources">Our Helpline</Link>
                  </li>
                </ul>
              </div>
            </div>
            <section
              className="sub-banner helpline"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="cntnt">
                  <h1>
                    <Text string={content.banner_heading} />
                  </h1>
                  <Text string={content.banner_desc} parse={true} />
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="helpline-sec">
              <div className="contain">
                <div className="help-flex flex">
                  <div className="col">
                    <div className="inner flex">
                      <div className="help-icon">
                        <ImageControl folder="images" src={content.image2} />
                      </div>
                      <div className="cntnt">
                        <h5>
                          <Text string={content.banner_card1_heading} />
                        </h5>
                        <span>
                          <Text string={content.banner_card1_description} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inner flex">
                      <div className="help-icon">
                        <ImageControl folder="images" src={content.image3} />
                      </div>
                      <div className="cntnt">
                        <h5>
                          <Text string={content.banner_card2_heading} />
                        </h5>
                        <span>
                          <Text string={content.banner_card2_description} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inner flex">
                      <div className="help-icon">
                        <ImageControl folder="images" src={content.image4} />
                      </div>
                      <div className="cntnt">
                        <h5>
                          <Text string={content.banner_card3_heading} />
                        </h5>
                        <span>
                          <Text string={content.banner_card3_description} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="center-cntnt">
                  <Text string={content.section2_main_heading} parse={true} />
                </div>
                <div className="flex-box-help flex">
                  <div className="col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image5}
                        isLazy={true}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="inner">
                      <h4>
                        <Text string={content.section2_heading} />
                      </h4>
                      <Text string={content.section2_detail} parse={true} />
                    </div>
                  </div>
                </div>
                <div className="search-box">
                  <div className="flex">
                    <div className="colL">
                      <h4>
                        <Text string={content.section3_heading} />
                      </h4>
                      <p>
                        <Text string={content.section3_detail} />
                      </p>
                    </div>
                    <div className="colR">
                      {/* <form action="?" method>
                        <div className="relative">
                          <input
                            type="text"
                            className="txtBox"
                            name
                            placeholder={content.section3_field_title}
                          />
                          <button type="submit" className="webBtn">
                            <Text string={content.section3_btn_text} />
                          </button>
                        </div>
                      </form> */}
                      <div className="bTn">
                        <Link
                          to='/national-directory'
                          className="webBtn"
                        >
                          <Text string={content.section3_btn_text} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="help-resources">
                  <div className="text-center">
                    <h1>
                      <Text string={content.section4_heading} />
                    </h1>
                  </div>
                  <Text string={content.section4_detail} parse={true} />
                </div>
              </div>
            </section>
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default HelpAndResourcesContainer;

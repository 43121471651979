import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, reset_password } from "../../states/actions/resetPassword";
import { Link } from "react-router-dom";
import StaticImage from "../../components/StaticImage";
import LoadingScreen from "../../components/LoadingScreen";
import ImageControl from "../../components/ImageControl";
import FormProcessingSpinner from "../../components/FormProcessingSpinner";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import Text from "../../components/Text";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { getBackgroundImageUrlThumb } from "../../helpers/helpers";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
const resetId = localStorage.getItem("reset_id");
const ResetContainer = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });
    const password = useRef({});
    password.current = watch("password", "");
    const dispatch = useDispatch();

    const data = useSelector((state) => state.resetPassword.content);
    const isLoading = useSelector((state) => state.resetPassword.isLoading);
    const isFormProcessing = useSelector(
        (state) => state.resetPassword.isFormProcessing
    );
    const { content, site_settings, meta_desc } = data;
    useEffect(() => {
        dispatch(fetchData());
    }, []);

    const onSubmit = (data) => {
        // console.log(data); return;
        let newData = { ...data, reset_id: resetId }
        dispatch(reset_password(newData));
    };



    useDocumentTitle(data.page_title);
    return (
        <>
            <LoadingScreen isLoading={isLoading} />
            {!isLoading && (
                <div>
                    <ToastContainer />
                    <main>
                        <section className="form-blk">
                            <div className="flex">
                                <div
                                    className="colL"
                                    style={{
                                        backgroundImage: `url(${getBackgroundImageUrlThumb(
                                            content.image1,
                                            500
                                        )})`
                                    }}
                                >
                                    <div className="image">
                                        <Link to="/">
                                            <ImageControl
                                                folder="images"
                                                src={site_settings.site_logo}
                                            />
                                        </Link>
                                        <h1 className="bold margn_top_30">
                                            <Text string={content.heading} />
                                        </h1>
                                        <h5 className="regular">
                                            <Text string={content.detail} />
                                        </h5>
                                    </div>
                                </div>
                                <div className="colR">
                                    <div className="inner">
                                        <h2>Reset Password</h2>

                                        <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row formRow">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                                                    <div className="txtGrp">
                                                        <h5>Password</h5>
                                                        <h5>
                                                            <input
                                                                type="password"
                                                                name="password"
                                                                className="txtBox"
                                                                placeholder="Enter password"
                                                                {...register("password", {
                                                                    required: "Required", pattern: {
                                                                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]{8,}$/,
                                                                        message: "The password should contain at least 8 characters, that contain at least one lowercase letter, one uppercase letter and one numeric digit."
                                                                    }
                                                                })}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="password"
                                                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                                            />
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                                                    <div className="txtGrp">
                                                        <h5>Confirm Password</h5>
                                                        <h5>
                                                            <input
                                                                type="password"
                                                                name="confirmPassword"
                                                                className="txtBox"
                                                                placeholder="Enter password again"
                                                                {...register("confirmPassword", {
                                                                    required: "Required",
                                                                    validate: (val) => {
                                                                        if (watch('password') != val) {
                                                                            return "Your passwords do no match";
                                                                        }
                                                                    },
                                                                })}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="confirmPassword"
                                                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                                            />
                                                        </h5>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                                                    <button
                                                        type="submit"
                                                        className="webBtn lgBtn"
                                                        disabled={isFormProcessing ? true : false}
                                                    >
                                                        Submit
                                                        <FormProcessingSpinner
                                                            isFormProcessing={isFormProcessing}
                                                        />
                                                    </button>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                                                    <div className="haveAccount margn_top_30">
                                                        <span>Don’t have an account ?</span>
                                                        <Link
                                                            to="/auth/signup"
                                                            className="semi"
                                                            id="member"
                                                        >
                                                            Signup
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            )}
        </>
    );
};
export default ResetContainer;

import http from "../../helpers/http";
import { doObjToFormData } from "../../helpers/helpers";

import {
  FETCH_RESCUE_STORY_DETAIL_CONTENT_SUCCESS,
  FETCH_RESCUE_STORY_DETAIL_CONTENT_FAILED
} from "../actions/actionTypes";

export const fetchData = (id) => (dispatch) => {
  http
    .get("rescue-story-detail/" + id)
    .then(({ data }) => {
      dispatch({
        type: FETCH_RESCUE_STORY_DETAIL_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_RESCUE_STORY_DETAIL_CONTENT_FAILED,
        payload: error
      });
    });
};

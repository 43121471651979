import http from "../../helpers/http";
import { doObjToFormData } from "../../helpers/helpers";

import {
  FETCH_NEWS_DETAIL_CONTENT_SUCCESS,
  FETCH_NEWS_DETAIL_CONTENT_FAILED
} from "../actions/actionTypes";

export const fetchData = (id) => (dispatch) => {
  http
    .post("news-detail", doObjToFormData({ id: id }))
    .then(({ data }) => {
      dispatch({
        type: FETCH_NEWS_DETAIL_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_NEWS_DETAIL_CONTENT_FAILED,
        payload: error
      });
    });
};

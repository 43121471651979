import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, fetchNews } from "../states/actions/currentAffairs";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import {
  getBackgroundImageUrl,
  getUploadsUrl,
  newsDateFormat
} from "../helpers/helpers";
import BannerVideo from "../components/BannerVideo";
import ContentBannerVideo from "../components/ContentBannerVideo";
import useScrollTop from "../hooks/useScrollTop";
import axios from "axios";
import StaticImage from "../components/StaticImage";
const _ = require("lodash");

const CurrentAffairsContainer = () => {
  const data = useSelector((state) => state.currentAffairs.content);
  const isLoading = useSelector((state) => state.currentAffairs.isLoading);
  const news = useSelector((state) => state.currentAffairs.news);
  const dispatch = useDispatch();
  const { content, site_settings, blogs } = data;

  useEffect(() => {
    dispatch(fetchData());
    // dispatch(fetchNews());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  // useEffect(() => {
  //   console.log(news);
  // }, news);


  useDocumentTitle(data.page_title);
  useScrollTop();

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index">
            <div className="mini-label">
              <div className="contain">
                <ul>
                  <li>
                    <Link to="/help-and-resources">Help &amp; Resources</Link>
                  </li>
                  <li>
                    <Link to="/current-affairs">Current Affairs(News)</Link>
                  </li>
                </ul>
              </div>
            </div>
            <section
              className="sub-banner current-affairs"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="videoBanner">
                  <ContentBannerVideo poster={content.poster} video={content.video} muted={false} />
                </div>
              </div>
            </section>
            <section className="playing_listing">
              <div className="contain">
                <div className="feature-news dark_theme">
                  <div className="play-list flex">
                    {news.slice(0, 8).map((nws, index) => (
                      <div className="col" key={index}>
                        <div className="inner">
                          <div className="image">
                            <img src={nws.urlToImage} />
                            <a href={nws.url} target="_blank" className=""></a>
                          </div>
                          <div className="contnt">
                            <a href={nws.url} target="_blank">
                              <Text string={nws.title} />
                            </a>
                            <ul className="view-detail flex">
                              <li>
                                <em>{newsDateFormat(nws.publishedAt)}</em>
                              </li>
                              <li>&nbsp;</li>
                              <li>
                                <em>
                                  <Text string={nws.author} />
                                </em>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
            <section className="news_more">
              <div className="contain">
                <div className="flex">
                  {news.slice(8, 16).map((nws, index) => (
                    <div className="col1 col" key={index}>
                      <div className="inner">
                        <div className="image">
                          <img src={nws.urlToImage} />
                        </div>
                        <div className="_cntnt">
                          <h5>
                            <a href={nws.url} target="_blank">
                              <Text string={nws.title} />
                            </a>
                          </h5>
                          <p>
                            <Text string={nws.description} />
                          </p>
                          <a href={nws.url} target="_blank">
                            Read more...
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default CurrentAffairsContainer;

import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSiteSettings } from "./states/actions/fetchSiteSettings";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import LoadingScreen from "./components/LoadingScreen";

import HomeContainer from "./containers/HomeContainer";
import WhatIsHumanTraffik from "./containers/WhatIsHumanTraffik";
import WhatIsSexTraffikicngContainer from "./containers/WhatIsSexTraffikicngContainer";
import FactAndStatsContainer from "./containers/FactAndStatsContainer";
import PolicyAndLegislationContainer from "./containers/PolicyAndLegislationContainer";
import HelpAndResourcesContainer from "./containers/HelpAndResourcesContainer";
import TraffikAndSexContainer from "./containers/TraffikAndSexContainer";
import NationalDirectoryContainer from "./containers/NationalDirectoryContainer";
import CurrentAffairsContainer from "./containers/CurrentAffairsContainer";
import BlogDetailContainer from "./containers/BlogDetailContainer";
import NewsDetailContainer from "./containers/NewsDetailContainer";
import RescueStoriesContainer from "./containers/RescueStoriesContainer";
import RescueStoryDetailContainer from "./containers/RescueStoryDetailContainer";
import ShareYourStoriesContainer from "./containers/ShareYourStoriesContainer";
import ProjectUniteContainer from "./containers/ProjectUniteContainer";
import OurSponersContainer from "./containers/OurSponersContainer";
import DonateNowContainer from "./containers/DonateNowContainer";
import CorporatePartnersContainer from "./containers/CorporatePartnersContainer";
import StartAFundraiserContainer from "./containers/StartAFundraiserContainer";
import ContactUsContainer from "./containers/ContactUsContainer";
import GoogleNews from "./containers/google-news";
import NearEventsContainer from "./containers/NearEventsContainer";
import TermsAndConditions from "./containers/terms-conditions";
import PrivacyPolicy from "./containers/privacy-policy";
import MissionStatement from "./containers/mission-statement";
import OurActivity from "./containers/OurActivity";


import SignUpContainer from "./containers/auth/SignUpContainer";
import SignInContainer from "./containers/auth/SignInContainer";
import Profile from "./containers/profile";
import MemberVerification from './containers/auth/member-verification';
import Verification from './containers/auth/verification';
import Subscription from "./containers/subscription";
import Paymentinfo from "./containers/payment-info";

import Error from './components/error';
import ForgetContainer from './containers/auth/ForgetContainer';
import Reset from './containers/auth/reset-req';
import ResetContainer from './containers/auth/ResetContainer';


const authToken = localStorage.getItem("authToken");
const resetId = localStorage.getItem("reset_id");
ReactGA.initialize("G-8RVDR9K4P1");
const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSiteSettings());
  }, []);
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  // console.log(siteSettings)
  const isLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
  const member = siteSettings?.member;
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return <>
    <LoadingScreen isLoading={isLoading} />
    {!isLoading && (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeContainer />} />
          <Route
            path="/what-is-human-trafficking"
            element={<WhatIsHumanTraffik />}
          />
          <Route
            path="/what-is-sex-trafficking"
            element={<WhatIsSexTraffikicngContainer />}
          />
          <Route
            path="/fact-and-statistics"
            element={<FactAndStatsContainer />}
          />
          <Route
            path="/policy-and-legislation"
            element={<PolicyAndLegislationContainer />}
          />
          <Route
            path="/help-and-resources"
            element={<HelpAndResourcesContainer />}
          />
          <Route path="/traffik-and-sex" element={<TraffikAndSexContainer />} />
          <Route
            path="/national-directory"
            element={<NationalDirectoryContainer />}
          />
          <Route path="/current-affairs" element={<CurrentAffairsContainer />} />
          <Route
            path="/current-affair/story-detail/:id"
            element={<BlogDetailContainer />}
          />
          <Route
            path="/current-affair/news-detail/:id"
            element={<NewsDetailContainer />}
          />
          <Route path="/rescue-stories" element={<RescueStoriesContainer />} />
          <Route
            path="/rescue-stories/detail/:id"
            element={<RescueStoryDetailContainer />}
          />
          <Route
            path="/share-your-stories"
            element={<ShareYourStoriesContainer />}
          />
          <Route path="/project-unite" element={<ProjectUniteContainer />} />
          <Route path="/our-sponsers" element={<OurSponersContainer />} />
          <Route path="/donate-now" element={<DonateNowContainer />} />
          <Route
            path="/corporate-partners"
            element={<CorporatePartnersContainer />}
          />
          <Route
            path="/start-a-fundraiser"
            element={<StartAFundraiserContainer />}
          />
          <Route path="/contact-us" element={<ContactUsContainer />} />
          <Route path="/news" element={<GoogleNews />} />
          <Route path="/events-near-you" element={<NearEventsContainer />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/mission-statement" element={<MissionStatement />} />
          <Route path="/our-activity" element={<OurActivity />} />


          {/* User signup and profile routes */}
          <Route exact path="/auth/signup" element={(authToken === null || authToken === undefined || member === null || member === undefined || member === '') ? <SignUpContainer /> : <Navigate to="/profile" />} />
          <Route exact path="/auth/signin" element={(authToken === null || authToken === undefined || member === null || member === undefined || member === '') ? <SignInContainer /> : <Navigate to="/profile" />} />
          <Route exact path="/auth/forget-password" element={(authToken === null || authToken === undefined || member === null || member === undefined || member === '') ? <ForgetContainer /> : <Navigate to="/profile" />} />
          <Route exact path="/auth/reset-password" element={((authToken === null || authToken === undefined || member === null || member === undefined || member === '') && resetId !== null && resetId !== undefined && resetId !== '') ? <ResetContainer /> : <Navigate to="/profile" />} />
          <Route path="/reset/:mem_code" element={<Reset />} />
          <Route
            exact
            path="/profile"
            element={authToken && authToken != null && member?.mem_id > 0 ? <Profile /> : <Navigate to="/auth/signin" />}
          />
          <Route path="/member-verification" element={authToken && authToken != null && member?.mem_id > 0 ? <MemberVerification /> : <Navigate to="/auth/signin" />} />
          <Route path="/verification/:token" element={<Verification />} />
          <Route path="/subscription" element={authToken && authToken != null && member?.mem_id > 0 ? <Subscription /> : <Navigate to="/auth/signin" />} />
          {/* <Route path="/payment-info" element={<Paymentinfo />} /> */}

          <Route path="*" element={<Error />} />
          <Route path="/404" element={<Error />} />
        </Routes>
      </BrowserRouter>
    )}
  </>
}

export default App;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, saveDonation } from "../states/actions/donateNow";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import useScrollTop from "../hooks/useScrollTop";
import Breadcrumbs from "../components/donate/breadcrumbs";
import DonateBanner from "../components/donate/donateBanner";
import DonateFormSection from "../components/donate/donateFormSection";
import CaughtTraffic from "../components/donate/caught-traffic";
import EnormousImpact from "../components/donate/impact";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../utils/siteSettings";

const DonateNowContainer = () => {
  const [donateAmount, setDonateAmount] = useState(50);
  const [donateType, setDonateType] = useState("one time");
  const data = useSelector((state) => state.donateNow.content);
  const isLoading = useSelector((state) => state.donateNow.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings } = data;
  const isFormProcessing = useSelector(
    (state) => state.donateNow.isFormProcessing
  );
  const isCompleted = useSelector((state) => state.donateNow.isCompleted);
  const handleDonation = (formData) => {
    dispatch(saveDonation(formData));
  };
  const handleImpactDonation = (amount, type = 'one time') => {
    setDonateType(type)
    setDonateAmount(parseFloat(amount))
    const element = document.getElementById('impactForm');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    dispatch(fetchData());
  }, []);

  useEffect(() => {
    if (isFormProcessing) {
      toast.success("Donation sent successfully.", TOAST_SETTINGS);
      setTimeout(() => {
        window.location.replace("/donate-now");
      }, 2000);
    }
  }, [isFormProcessing]);
  useEffect(() => {
    document.body.classList.remove("liveTvBody");
  }, []);
  useDocumentTitle(data.page_title);
  useScrollTop();

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <ToastContainer />
          <main className="index" donate_now>
            <Breadcrumbs />
            <DonateBanner image={content.image1} />
            {/* banner */}
            <div id="impactForm">
              <DonateFormSection
                content={content}
                handleDonation={handleDonation}
                isCompleted={isCompleted}
                donateAmount={donateAmount}
                donateType={donateType}
                setDonateType={setDonateType}
              />
            </div>
            {/*rescue section */}
            <CaughtTraffic content={content} handleImpactDonation={handleImpactDonation} />
            {/* caught */}
            <EnormousImpact content={content} handleImpactDonation={handleImpactDonation} />
            {/* Impact */}
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default DonateNowContainer;

import React, { useState } from "react";
import Text from './Text';
import { useForm } from "react-hook-form";
import { doObjToFormData, postData } from "../helpers/helpers";
import { TOAST_SETTINGS } from "../utils/siteSettings";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from '@hookform/error-message';
import 'react-toastify/dist/ReactToastify.css';
export default function BecomePartner({ content }) {
    const [bePartner, setBePartner] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });
    const onSubmit = async form_data => {
        setBePartner(true);
        await postData("api/auth/save-become-partner", doObjToFormData(form_data)).then((data) => {
            // console.log(data)
            setBePartner(false);
            if (data.status === 1) {
                toast.success(data.msg,
                    TOAST_SETTINGS
                );
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }
            else {
                toast.error(data.msg,
                    TOAST_SETTINGS
                );
            }
        });
    }
    return <>
        <ToastContainer />
        <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
            <h1>
                <Text string={content.form_heading} />
            </h1>
            <div className="sub_heading heading">
                <Text string={content.form_tagline} />
            </div>
            <div className="row formRow">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="First Name"
                            {...register("fname", {
                                pattern: {
                                    value: /^[a-zA-Z][a-zA-Z ]*$/,
                                    message: 'Invalid value',
                                },
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="fname"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="Last Name"
                            {...register("lname", {
                                pattern: {
                                    value: /^[a-zA-Z][a-zA-Z ]*$/,
                                    message: 'Invalid value',
                                },
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="lname"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="Email"
                            {...register("email", {
                                required: "Required", pattern: {
                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                    message: "Email format is not valid!"
                                }
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="Phone No"
                            {...register("phone", {
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="phone"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    <div className="txtGrp">
                        <input
                            type="text"
                            name
                            id
                            className="txtBox"
                            placeholder="Company Name"
                            {...register("company_name", {
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="company_name"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                    <div className="txtGrp">
                        <textarea
                            name
                            id
                            className="txtBox"
                            placeholder="Message"
                            defaultValue={""}
                            {...register("msg", {
                                required: "Required"
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="msg"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
            </div>
            <div className="bTn formBtn">
                <button type="submit" className="webBtn lgBtn" disabled={(bePartner === true) ? 'disabled' : ''}>
                    <Text string={content.form_button_text} /> {(bePartner === true) ? <><i className="spinner"></i></> : ''}
                </button>
            </div>
        </form>
    </>
}

import { doObjToFormData } from "../../helpers/helpers";
import http from "../../helpers/http";
import { toast } from "react-toastify";
import Text from "../../components/Text";
import { TOAST_SETTINGS } from "../../utils/siteSettings";

import {
  FETCH_NEAR_EVENTS_CONTENT_SUCCESS,
  FETCH_NEAR_EVENTS_CONTENT_FAILED,
  SEARCH_NEARBY_EVENTS,
  SEARCH_NEARBY_EVENTS_SUCCESS,
  SEARCH_NEARBY_EVENTS_FAILED
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
  http
    .get("near-events")
    .then(({ data }) => {
      dispatch({
        type: FETCH_NEAR_EVENTS_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_NEAR_EVENTS_CONTENT_FAILED,
        payload: error
      });
    });
};

export const searchNearbyEvents = (formData) => (dispatch) => {
  dispatch({
    type: SEARCH_NEARBY_EVENTS,
    payload: null
  });
  http
    .post("search-nearby-events", doObjToFormData(formData))
    .then(({ data }) => {
      console.log(data)
      if (data.validationErrors) {
        toast.error(
          <Text string={data.validationErrors} parse={true} />,
          TOAST_SETTINGS
        );
      } else {
        toast.success("Message sent successfully.", TOAST_SETTINGS);
      }
      dispatch({
        type: SEARCH_NEARBY_EVENTS_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: SEARCH_NEARBY_EVENTS_FAILED,
        payload: error
      });
    });
};

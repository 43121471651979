import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, saveContactMessage } from "../states/actions/contactUs";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import ImageControl from "../components/ImageControl";
import FormProcessingSpinner from "../components/FormProcessingSpinner";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const ContactUsContainer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const input_obj = useSelector((state) => state.contactUs.input_obj);
  const isFormSucceed = useSelector((state) => state.contactUs.isFormSucceed);

  const data = useSelector((state) => state.contactUs.content);
  const isLoading = useSelector((state) => state.contactUs.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.contactUs.isFormProcessing
  );
  const dispatch = useDispatch();
  const { content, site_settings } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    if (isFormSucceed) {
      if (input_obj?.name !== undefined && input_obj?.name !== null && input_obj) {
        setName(input_obj?.name)
      }
      if (input_obj?.email !== undefined && input_obj?.email !== null && input_obj) {
        setEmail(input_obj?.email)
      }
      if (input_obj?.message !== undefined && input_obj?.message !== null && input_obj) {
        setMessage(input_obj?.message)
      }
    }
  }, [input_obj]);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = {
      name,
      email,
      message
    };
    dispatch(saveContactMessage(formData));
  };

  useDocumentTitle(data.page_title);
  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <ToastContainer />
          <main className="common contact">
            <section id="contact">
              <div className="flex">
                <div className="colL">
                  <ImageControl folder="images" src={content.image1} />
                </div>
                <div className="colR">
                  <div className="contain">
                    <div className="heading">
                      <h1>
                        <Text string={content.banner_heading} />
                      </h1>
                      <p>
                        <Text string={content.banner_desc} />
                      </p>
                    </div>
                    <div className="flex">
                      <div className="coll">
                        <div className="icon">
                          <ImageControl folder="images" src={content.image2} />
                        </div>
                        <h5>
                          <Text string={content.heading_1} />
                        </h5>
                        <h5 className="regular">
                          <Text string={content.tagline1} />
                        </h5>
                      </div>
                      <div className="coll">
                        <div className="icon">
                          <ImageControl folder="images" src={content.image3} />
                        </div>
                        <h5>
                          <Text string={content.heading_2} />
                        </h5>
                        <h5 className="regular">
                          <a href={`tel:${content.tagline2}`}>
                            <Text string={content.tagline2} />
                          </a>
                        </h5>
                      </div>
                      <div className="coll">
                        <div className="icon">
                          <ImageControl folder="images" src={content.image4} />
                        </div>
                        <h5>
                          <Text string={content.heading_3} />
                        </h5>
                        <h5 className="regular">
                          <a href={`mailto:${content.tagline3}`}>
                            <Text string={content.tagline3} />
                          </a>
                        </h5>
                      </div>
                    </div>
                    <form action method="post" className="contactForm">
                      <Text string={content.mid_text} parse={true} />
                      <div className="row formRow">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <div className="txtGrp">
                            <label htmlFor className="label">
                              <Text string={content.field_heading_1} />
                            </label>
                            <input
                              type="text"
                              value={name}
                              className="txtBox"
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <div className="txtGrp">
                            <label htmlFor className="label">
                              <Text string={content.field_heading_2} />
                            </label>
                            <input
                              type="text"
                              value={email}
                              className="txtBox"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                          <div className="txtGrp">
                            <label htmlFor className="label">
                              <Text string={content.field_heading_3} />
                            </label>
                            <textarea
                              className="txtBox"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="bTn formBtn">
                        <button
                          type="button"
                          className="webBtn"
                          onClick={handleSubmit}
                          disabled={isFormProcessing ? true : false}
                        >
                          <Text string={content.button_heading} />
                          <i className="fi-arrow-right" />
                          <FormProcessingSpinner
                            isFormProcessing={isFormProcessing}
                          />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
            {/* contact */}
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default ContactUsContainer;

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, searchNearbyEvents } from "../states/actions/nearEvents";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import FormProcessingSpinner from "../components/FormProcessingSpinner";
import {
  eventTimeFormat,
  getBackgroundImageUrl,
  newsDateFormat
} from "../helpers/helpers";
import StaticImage from "../components/StaticImage";
import useScrollTop from "../hooks/useScrollTop";
import { isEmpty } from "lodash";
import EventsBlk from "../components/eventsBlk";

const NearEventsContainer = () => {
  //STATES
  const data = useSelector((state) => state.nearEvents.content);
  const isLoading = useSelector((state) => state.nearEvents.isLoading);
  const events = useSelector((state) => state.nearEvents.events);
  const isFormProcessing = useSelector(
    (state) => state.nearEvents.isFormProcessing
  );
  const dispatch = useDispatch();
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const { content, site_settings, states } = data;
  // REFERENCES
  const stateInput = useRef(null);

  // DOM EVENTS AND API
  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state, zipcode);
    // if (isEmpty(state) && isEmpty(zipcode)) {
    //   stateInput.current.focus();
    //   return false;
    // }
    let formData = {
      state,
      zipcode
    };
    dispatch(searchNearbyEvents(formData));
  };
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isSiteLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
  const member = siteSettings?.member;

  useDocumentTitle(data.page_title);
  useScrollTop();
  if (parseInt(member?.mem_verified) === 0) {
    return window.location.replace("/member-verification");
  }
  return (
    <>
      <LoadingScreen isLoading={isLoading || isSiteLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          {
            member?.subscription !== undefined && member?.subscription !== null && member?.subscription?.id > 0 ?
              <main className="index event">
                <div className="mini-label">
                  <div className="contain">
                    <ul>
                      <li>
                        <Link to="/events-near-you">{content.banner_heading}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <section
                  className="sub-banner event-banner"
                  style={{
                    backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
                  }}
                >
                  <div className="contain">
                    <div className="flex">
                      <div className="colL">
                        <div className="contnt">
                          <h1>
                            <Text string={content.banner_heading} />
                          </h1>
                          <p>
                            <Text string={content.banner_short_dec} />
                          </p>
                        </div>
                      </div>
                      <div className="colR">
                        <form action method="POST">
                          <div className="row formRow">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                              <p>State</p>
                              <div className="txtGrp">
                                <select
                                  name="state"
                                  className="txtBox"
                                  value={state}
                                  onChange={(e) => setState(e.target.value)}
                                  ref={stateInput}
                                >
                                  <option value="">Select State</option>
                                  {states.map((s, index) => (
                                    <option value={s.id}>{s.name}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xx-6">
                              <p>Zip Code</p>
                              <input
                                type="text"
                                name="zipcode"
                                className="txtBox"
                                value={zipcode}
                                onChange={(e) => setZipcode(e.target.value)}
                              />
                            </div>
                            <div className="bTn formBtn">
                              <button
                                type="button"
                                className="webBtn"
                                onClick={handleSubmit}
                                disabled={isFormProcessing ? true : false}
                              >
                                <Text string="Search" />
                                <FormProcessingSpinner
                                  isFormProcessing={isFormProcessing}
                                />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
                {/* banner */}
                <section className="event">
                  <div className="contain">
                    {isFormProcessing ? (
                      <h3>Fetching...</h3>
                    ) : isEmpty(events) ? (
                      <h3>No Event Found.</h3>
                    ) : (
                      <EventsBlk items={events} />
                    )}
                  </div>
                </section>
                {/* events */}
              </main>
              :
              <main className="liveMain subscribe_upgrade">
                <section className="innerBox">
                  <h3>Subscribe Now</h3>
                  <p>If you want to view events nearby you please subscribe Now.</p>
                  <div className="bTn text-center">
                    <NavLink to="/subscription" className="webBtn colorBtn">Subscription Plans</NavLink>
                  </div>
                </section>
              </main>
          }
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default NearEventsContainer;

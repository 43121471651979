import React, { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/projectUnite";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import SocialIconCard from "../components/SocialIconCard";
import useScrollTop from "../hooks/useScrollTop";
import SubscribeForm from "../components/subscribe";

const ProjectUniteContainer = () => {
  const data = useSelector((state) => state.projectUnite.content);
  const isLoading = useSelector((state) => state.projectUnite.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  useDocumentTitle(data.page_title);
  useScrollTop();

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index" sponsorship>
            <div className="mini-label">
              <div className="contain">
                <ul>
                  <li>
                    <Link to="/help-and-resources">Help &amp; Resources</Link>
                  </li>
                  <li>
                    <Link to="/project-unite">Project unite</Link>
                  </li>
                </ul>
              </div>
            </div>
            <section
              className="sub-banner project-unite"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="cntnt text-center">
                  <h1>
                    <Text string={content.banner_heading} />
                  </h1>
                  <p>
                    <Text string={content.banner_desc} />
                  </p>
                  <div className="bTn">
                    <Link to={content.banner_button_link} className="webBtn">
                      <Text string={content.banner_button_title} />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            {/* banner */}
            {/* <section className="half-slider">
              <div className="contain">
                <div className="h-slider">
                  <div id="owl-video" className="owl-carousel owl-theme">
                    <div className="inner">
                      <div className="image">
                        <div className="videoBlk">
                          <video poster="images/vid.png">
                            <source src="videos/work.mp4" type="video/mp4" />
                          </video>
                          <div className="videoBtn  fi-play" />
                        </div>
                      </div>
                      <div className="txt">
                        <h4>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="inner">
                      <div className="image">
                        <div className="videoBlk">
                          <video poster="images/vid.png">
                            <source src="videos/work.mp4" type="video/mp4" />
                          </video>
                          <div className="videoBtn fi-play" />
                        </div>
                      </div>
                      <div className="txt">
                        <h4>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="inner">
                      <div className="image">
                        <div className="videoBlk">
                          <video poster="images/vid.png">
                            <source src="videos/work.mp4" type="video/mp4" />
                          </video>
                          <div className="videoBtn fi-play" />
                        </div>
                      </div>
                      <div className="txt">
                        <h4>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* banner */}
            <section className="involved">
              <div className="ab_image ab_imageBottom">
                {/* <img src="/images/5.png" /> */}
              </div>
              <div className="contain">
                <div className="hire_div">
                  <div className="hire-description">
                    <h2 className="heading_title">
                      <Text string={content.section3_heading} />
                    </h2>
                    <p>
                      <Text string={content.section3_detail} />
                    </p>
                    <SubscribeForm content={content} />
                    <div className="flex involedSocail">
                      <SocialIconCard src={content.image2} link={content?.social_link2} />
                      <SocialIconCard src={content.image3} link={content?.social_link3} />
                      <SocialIconCard src={content.image4} link={content?.social_link4} />
                      <SocialIconCard src={content.image5} link={content?.social_link5} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default ProjectUniteContainer;

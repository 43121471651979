import React, { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/shareStory";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import useScrollTop from "../hooks/useScrollTop";
import ShareStoriesForm from "../components/share-stories-form";

const ShareYourStoriesContainer = () => {
  const data = useSelector((state) => state.shareStory.content);
  const isLoading = useSelector((state) => state.shareStory.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings, page_title } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  useDocumentTitle(page_title);
  useScrollTop();

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index" share_stroy>
            <div className="mini-label">
              <div className="contain">
                <ul>
                  <li>
                    <Link to="/help-and-resources">Help &amp; Resources</Link>
                  </li>
                  <li>
                    <Link to="/share-your-stories">Share Your Story</Link>
                  </li>
                </ul>
              </div>
            </div>
            <section
              className="sub-banner share_stroy"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="cntnt">
                  <h1>
                    <Text string={content.banner_heading} />
                  </h1>
                  <p>
                    <Text string={content.banner_desc} />
                  </p>
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="share-sec">
              <div className="contain">
                <div className="share-flex flex">
                  <div className="colL">
                    <div className="image">
                      <ImageControl folder="images" src={content.image2} />
                    </div>
                  </div>
                  <div className="colR">
                    <ShareStoriesForm />
                  </div>
                </div>
              </div>
            </section>
            {/*rescue section */}
            <section className="starters">
              <div className="contain">
                <div className="cntnt_starters">
                  <h3 className="heading">
                    <Text string={content.after_card_heading} />
                  </h3>
                  <Text string={content.after_card_detail} parse={true} />
                </div>
              </div>
            </section>
            {/* Starters */}
            <section className="map">
              <div className="contain  text-center">
                <h1>
                  <Text string={content.section3_heading} />
                </h1>
                <div className="cntntt">
                  <h5 className="regular">
                    <Text string={content.section3_detail} />
                  </h5>
                </div>
                <div className="bTn margn_bottom_30">
                  <Link
                    to={content.section3_upper_button_link}
                    className="webBtn"
                  >
                    <Text string={content.section3_upper_button_text} />
                  </Link>
                </div>
                <p>
                  <Link to={content.section3_lower_button_link}>
                    <Text string={content.section3_lower_button_text} />
                  </Link>
                </p>
              </div>
            </section>
            {/* map */}
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default ShareYourStoriesContainer;

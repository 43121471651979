import {
    FETCH_RESET_PASSWORD_CONTENT_SUCCESS,
    FETCH_RESET_PASSWORD_CONTENT_FAILED,
    RESET_PASSWORD_MESSAGE,
    RESET_PASSWORD_MESSAGE_SUCCESS,
    RESET_PASSWORD_MESSAGE_FAILED
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
    formData: {
        email: "",
        password: ""
    }
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_RESET_PASSWORD_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload
            };
        case FETCH_RESET_PASSWORD_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        case RESET_PASSWORD_MESSAGE:
            return {
                ...state,
                isFormProcessing: true
            };
        case RESET_PASSWORD_MESSAGE_SUCCESS:
            localStorage.setItem("authToken", payload.authToken);
            return {
                ...state,
                formData: {
                    email: "",
                    password: ""
                },
                isFormProcessing: false
            };
        case RESET_PASSWORD_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        default:
            return state;
    }
}

import React, { useState } from "react";
import Pagination from "./pagination";
import { format_date } from "../helpers/helpers";

export default function NewsBlk({ items }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8);

    //get Current posts
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);
    function paginate(pageNumber) {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 3)
    }
    console.log(items)
    return <>
        <div className="flex grid_blog_lsting_flex">
            {

                currentItems !== undefined && currentItems !== '' && currentItems !== null && currentItems?.length > 0 ?

                    currentItems?.map((item, index) => {
                        return (
                            <div className="col" key={index}>
                                <div className="inner">
                                    <a className="image" href={item?.url} target="_blank">
                                        <img src={item?.urlToImage ? item?.urlToImage : '/images/no-image.svg'} alt={item?.title} />
                                    </a>
                                    <div className="cntnt">
                                        <h3>
                                            <a href={item?.url} target="_blank">{item?.title} </a>
                                        </h3>
                                        <p className="auther_card">By {item?.author} on {format_date(item?.publishedAt)}</p>
                                        <p>
                                            {item?.description}
                                        </p>
                                        <a href={item?.url} target="_blank"><span>Read more</span> <i className="fi-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className='alert alert-danger'>No new(s) added yet!</div>
            }
        </div>
        <Pagination paginate={paginate} itemsPerPage={itemsPerPage} totalItems={items?.length} currentPage={currentPage} />
    </>;
}

import React from 'react'

export default function Pagination({ itemsPerPage, totalItems, paginate, currentPage }) {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }
    return (
        <>
            {
                pageNumbers.length > 1 ?
                    <div className="text-center">
                        <ul className="pagination">
                            {
                                pageNumbers.map(number => (
                                    <li key={number}>
                                        <a href="javascript:void(0)" onClick={() => paginate(number)} className={currentPage === number ? 'active' : ''}>{number}</a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    :
                    ""
            }

        </>

    )
}

import React from 'react'
import { getBackgroundImageUrl } from '../../helpers/helpers'

export default function DonateBanner({ image }) {
    return (
        <section
            className="sub-banner donate_now"
            style={{
                backgroundImage: `url(${getBackgroundImageUrl(image)})`
            }}
        ></section>
    )
}

import React from 'react'
import { Link } from 'react-router-dom';

export default function Breadcrumbs() {
    return (
        <div className="mini-label">
            <div className="contain">
                <ul>
                    <li>
                        <Link to="/donate-now">How to fight</Link>
                    </li>
                    <li>
                        <Link to="/donate-now">Donate Now</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

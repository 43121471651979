import { doObjToFormData } from "../../helpers/helpers";
import http from "../../helpers/http";
import { toast } from "react-toastify";
import Text from "../../components/Text";
import { TOAST_SETTINGS } from "../../utils/siteSettings";

import {
    FETCH_RESET_PASSWORD_CONTENT_SUCCESS,
    FETCH_RESET_PASSWORD_CONTENT_FAILED,
    RESET_PASSWORD_MESSAGE,
    RESET_PASSWORD_MESSAGE_SUCCESS,
    RESET_PASSWORD_MESSAGE_FAILED
} from "./actionTypes";

export const fetchData = () => (dispatch) => {
    http
        .get("reset-password")
        .then(({ data }) => {
            dispatch({
                type: FETCH_RESET_PASSWORD_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_RESET_PASSWORD_CONTENT_FAILED,
                payload: error
            });
        });
};

export const reset_password = (formData) => (dispatch) => {
    dispatch({
        type: RESET_PASSWORD_MESSAGE,
        payload: null
    });
    // console.log(doObjToFormData(formData))
    http
        .post("auth/reset-password", doObjToFormData(formData))
        .then(({ data }) => {
            // console.log(data);
            if (data.status) {
                toast.success(data?.msg, TOAST_SETTINGS);
                localStorage.removeItem("reset_id");
                setTimeout(() => {
                    window.location.replace("/auth/signin");
                }, 2000);
                dispatch({
                    type: RESET_PASSWORD_MESSAGE_SUCCESS,
                    payload: data
                });
            } else {
                if (data.validationErrors) {
                    toast.error(
                        <Text string={data.validationErrors} parse={true} />,
                        TOAST_SETTINGS
                    );
                }
                if (data.msg) {
                    toast.error(data.msg, TOAST_SETTINGS);
                }
                dispatch({
                    type: RESET_PASSWORD_MESSAGE_FAILED,
                    payload: null
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: RESET_PASSWORD_MESSAGE_FAILED,
                payload: error
            });
        });
};

export const googleSignin = (credentials) => (dispatch) => {
    // dispatch({
    //   type: RESET_PASSWORD_MESSAGE,
    //   payload: null
    // });
    http
        .post("auth/google-sign-in-account", doObjToFormData(credentials))
        .then(({ data }) => {
            if (data.status) {
                toast.success("Sign In successfull.", TOAST_SETTINGS);
                dispatch({
                    type: RESET_PASSWORD_MESSAGE_SUCCESS,
                    payload: data
                });
            } else {
                if (data.msg) {
                    toast.error(data.msg, TOAST_SETTINGS);
                }
                dispatch({
                    type: RESET_PASSWORD_MESSAGE_FAILED,
                    payload: null
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: RESET_PASSWORD_MESSAGE_FAILED,
                payload: error
            });
        });
};

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/policyAndLegislation";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import useScrollTop from "../hooks/useScrollTop";
import Faq from 'react-faq-component';


const PolicyAndLegislationContainer = () => {
  const [tabs, setTabs] = useState(1);
  const data = useSelector((state) => state.policyAndLegislation.content);
  const isLoading = useSelector(
    (state) => state.policyAndLegislation.isLoading
  );
  const dispatch = useDispatch();
  const { content, site_settings } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  // console.log(data.page_title)
  useDocumentTitle(data.page_title);
  useScrollTop();
  let faq_data = [
    {
      title: "The Trafficking Victims Protection Reauthorization Act of 2005",
      content: `<p>
      The Trafficking Victims Protection Reauthorization
      Act of 2003 (TVPRA 2003), Pub. L. No. 108-193,
      refined federal criminal provisions against
      trafficking, to include adding human trafficking
      crimes as a Racketeer Influenced and Corrupt
      Organizations Act (RICO) predicate, and created a
      civil remedy enabling trafficking victims to file
      lawsuits against their traffickers in federal
      district court.
    </p>
    <p>
      The TVPRA 2003 also established a Senior Policy
      Operating Group (SPOG) within the executive
      branch, to “coordinate activities of Federal
      departments and agencies regarding policies
      (including grants and grant policies) involving
      the international trafficking in persons and the
      implementation of” the TVPA. The SPOG consists of
      the senior officials from the agencies that work
      to address TIP, and is chaired by the Director of
      the Office to Monitor and Combat Trafficking
      Persons (TIP Office) of the Department of State
      (DOS).
    </p>`
    },
    {
      title: `The William Wilberforce Trafficking Victims
      Protection Reauthorization Act of 2008`,
      content: `<p>
      The Trafficking Victims Protection Reauthorization
      Act of 2003 (TVPRA 2003), Pub. L. No. 108-193,
      refined federal criminal provisions against
      trafficking, to include adding human trafficking
      crimes as a Racketeer Influenced and Corrupt
      Organizations Act (RICO) predicate, and created a
      civil remedy enabling trafficking victims to file
      lawsuits against their traffickers in federal
      district court.
    </p>
    <p>
      The TVPRA 2003 also established a Senior Policy
      Operating Group (SPOG) within the executive
      branch, to “coordinate activities of Federal
      departments and agencies regarding policies
      (including grants and grant policies) involving
      the international trafficking in persons and the
      implementation of” the TVPA. The SPOG consists of
      the senior officials from the agencies that work
      to address TIP, and is chaired by the Director of
      the Office to Monitor and Combat Trafficking
      Persons (TIP Office) of the Department of State
      (DOS).
    </p>`
    },
    {
      title: `The Trafficking Victims Protection Reauthorization
      Act of 2013`,
      content: `<p>
      The Trafficking Victims Protection Reauthorization
      Act of 2003 (TVPRA 2003), Pub. L. No. 108-193,
      refined federal criminal provisions against
      trafficking, to include adding human trafficking
      crimes as a Racketeer Influenced and Corrupt
      Organizations Act (RICO) predicate, and created a
      civil remedy enabling trafficking victims to file
      lawsuits against their traffickers in federal
      district court.
    </p>
    <p>
      The TVPRA 2003 also established a Senior Policy
      Operating Group (SPOG) within the executive
      branch, to “coordinate activities of Federal
      departments and agencies regarding policies
      (including grants and grant policies) involving
      the international trafficking in persons and the
      implementation of” the TVPA. The SPOG consists of
      the senior officials from the agencies that work
      to address TIP, and is chaired by the Director of
      the Office to Monitor and Combat Trafficking
      Persons (TIP Office) of the Department of State
      (DOS).
    </p>
    <p>
      In addition, the TVPRA 2003 mandated an annual
      report from the Attorney General to the U.S.
      Congress regarding U.S. governmental efforts to
      implement the TVPA.
    </p>`
    },
    {
      title: ` The Justice for Victims of Trafficking Act of 2015`,
      content: `<p>
      The Trafficking Victims Protection Reauthorization
      Act of 2003 (TVPRA 2003), Pub. L. No. 108-193,
      refined federal criminal provisions against
      trafficking, to include adding human trafficking
      crimes as a Racketeer Influenced and Corrupt
      Organizations Act (RICO) predicate, and created a
      civil remedy enabling trafficking victims to file
      lawsuits against their traffickers in federal
      district court.
    </p>
    <p>
      The TVPRA 2003 also established a Senior Policy
      Operating Group (SPOG) within the executive
      branch, to “coordinate activities of Federal
      departments and agencies regarding policies
      (including grants and grant policies) involving
      the international trafficking in persons and the
      implementation of” the TVPA. The SPOG consists of
      the senior officials from the agencies that work
      to address TIP, and is chaired by the Director of
      the Office to Monitor and Combat Trafficking
      Persons (TIP Office) of the Department of State
      (DOS).
    </p>
    <p>
      In addition, the TVPRA 2003 mandated an annual
      report from the Attorney General to the U.S.
      Congress regarding U.S. governmental efforts to
      implement the TVPA.
    </p>`
    }
  ];
  const faqData = {
    title: "",
    rows: faq_data
  };
  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index">
            <div className="mini-label">
              <div className="contain">
                <ul>
                  <li>
                    <Link to="/what-is-human-trafficking">
                      About Sex &amp; Trafficking
                    </Link>
                  </li>
                  <li>
                    <Link to="/policy-and-legislation">
                      POLICY &amp; LEGISLATION
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <section
              className="sub-banner policy-page"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="cntnt">
                  <h1>
                    <Text string={content.banner_heading} />
                  </h1>
                  <Text string={content.banner_desc} parse={true} />
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="cmn-tabs fact-tab">
              <div className="contain">
                <div className="flex">
                  <div className="colL">
                    <div className="tabs-lst">
                      <ul>
                        <li className="active">
                          <NavLink
                            to="/what-is-human-trafficking"
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            What is human trafficking?
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/what-is-sex-trafficking"
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            What is Sex trafficking?
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/fact-and-statistics"
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            FACTS, AND STATISTICS
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/policy-and-legislation"
                            className={({ isActive }) =>
                              isActive ? "active" : ""
                            }
                          >
                            POLICY &amp; LEGISLATION
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="side-poster relative">
                      <div className="image">
                        <ImageControl folder="images" src={content.image2} />
                      </div>
                      <div className="cntnt">
                        <div className="immage">
                          <ImageControl folder="images" src={content.image3} />
                        </div>
                        <h2>
                          <Text string={content.sec2_pu_heading} />
                        </h2>
                        <p>
                          <Text string={content.sec2_pu_desc} />
                        </p>
                        <div className="bTn">
                          <Link
                            to={content.sec2_pu_button_link}
                            className="webBtn"
                          >
                            {" "}
                            {content.sec2_pu_button_text}{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="colR">
                    <div className="inner">
                      <Text
                        string={content.sec2_right1_right_desc}
                        parse={true}
                      />
                    </div>
                    <div className="custom-tabs">
                      <ul className="nav nav-tabs">
                        <li className={tabs == 1 ? "active" : ""}>
                          <a className="a" onClick={() => setTabs(1)}>
                            <Text string={content.sec2_right_tab_heading1} />
                          </a>
                        </li>
                        <li className={tabs == 2 ? "active" : ""}>
                          <a className="b" onClick={() => setTabs(2)}>
                            <Text string={content.sec2_right_tab_heading2} />
                          </a>
                        </li>
                        <li className={tabs == 3 ? "active" : ""}>
                          <a className="c" onClick={() => setTabs(3)}>
                            <Text string={content.sec2_right_tab_heading3} />
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className={
                            tabs == 1
                              ? "tab-pane fade a active in"
                              : "tab-pane a"
                          }
                        >
                          <Text
                            string={content.sec2_right_tab_desc1}
                            parse={true}
                          />
                        </div>
                        <div
                          id="prosecution"
                          className={
                            tabs == 2
                              ? "tab-pane fade b active in"
                              : "tab-pane b"
                          }
                        >
                          <Text
                            string={content.sec2_right_tab_desc2}
                            parse={true}
                          />
                        </div>
                        <div
                          id="prevention"
                          className={
                            tabs == 3
                              ? "tab-pane fade c active in"
                              : "tab-pane c"
                          }
                        >
                          <Text
                            string={content.sec2_right_tab_desc3}
                            parse={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="faqBox">
                      {/* <div className="faqLst">
                        <Faq
                          data={faqData}
                        // styles={styles}
                        // config={config}
                        />
                      </div> */}
                      <div className="flex last-link">
                        <div className="link-icon">
                          <i className="fi-link" />
                        </div>
                        <div className="cntnt">
                          <h5>
                            <Text string={content.last_link_heading} />
                          </h5>
                          <a target="_blank" href={content.last_link_link}>
                            <Text string={content.last_link_link} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default PolicyAndLegislationContainer;

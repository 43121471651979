import React, { useEffect, useRef, useState } from 'react'

export default function PayPal(props) {
    let total_price = props.price;



    const paypal = useRef();

    useEffect(() => {

        window.paypal.Buttons({

            createOrder: (data, actions, err) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [
                        {
                            description: props?.description,
                            amount: {
                                currency_code: 'USD',
                                value: total_price,

                            }
                        }
                    ],

                })
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                // console.log(order);
                if (order.status === 'COMPLETED') {
                    props.getPaypalObject(order);
                }
            },
            onError: err => {
                console.log(err);
                props.errorPaypalOrder(err);
            },
            onCancel: function (data) {
                props.cancelPaypalOrder('cancel');
            }
        }).render(paypal.current)
    }, []);
    return (
        <div>
            <div ref={paypal}></div>
        </div>
    )
}

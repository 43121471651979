import React, { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/ourSponsors";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import StaticImage from "../components/StaticImage";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import useScrollTop from "../hooks/useScrollTop";

const OurSponersContainer = () => {
  const data = useSelector((state) => state.ourSponsors.content);
  const isLoading = useSelector((state) => state.ourSponsors.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  useDocumentTitle(data.page_title);
  useScrollTop();

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index" sponsorship>
            <div className="mini-label">
              <div className="contain">
                <ul>
                  <li>
                    <Link to="/help-and-resources">Help &amp; Resources</Link>
                  </li>
                  <li>
                    <Link to="/our-sponsers">Our Sponsors</Link>
                  </li>
                </ul>
              </div>
            </div>
            <section
              className="sub-banner sponsorship"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="cntnt">
                  <h1>
                    <Text string={content.banner_heading} />
                  </h1>
                  <p>
                    <Text string={content.banner_desc} />
                  </p>
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="lindsay">
              <div className="contain">
                <div className="flex">
                  <div className="col">
                    <div className="image">
                      <ImageControl folder="images" src={content.image2} />
                    </div>
                    <div className="whiteLbl flex">
                      <div className="smImage">
                        <ImageControl folder="images" src={content.image3} />
                      </div>
                      <div className="cntnt">
                        <h3>
                          <Text string={content.sec2_left_card_heading} />
                        </h3>
                        <h3 className="regular">
                          <Text string={content.sec2_left_card_short} />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="logo-lindsay">
                      <ImageControl folder="images" src={content.image4} />
                    </div>
                    <p>
                      <Text string={content.sec2_right_main_desc} />
                    </p>
                    <div className="bTn">
                      <a
                        target="_blank"
                        href={content.sec2_left_left_button_link}
                        className="blankBtn webBtn"
                      >
                        <Text string={content.sec2_left_left_button_text} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* lindsay */}
            <section className="warrior">
              <div className="contain text-center">
                <div className="cntnt">
                  <div className="bannerLogo margn_bottom_30">
                    <ImageControl folder="images" src={content.image5} />
                  </div>
                  <p>
                    <Text string={content.sec3_left_main_desc} />
                  </p>
                  <div className="bTn">
                    <a href={content.sec3_left_button_link} className="webBtn" target="_blank">
                      <Text string={content.sec3_left_button_text} />
                    </a>
                    <a
                      href={content.sec3_right_button_link} target="_blank"
                      className="webBtn lightBtn"
                    >
                      <StaticImage src="Group2794.png" />
                      <Text string={content.sec3_right_button_text} />
                    </a>
                  </div>
                </div>
              </div>
            </section>
            {/* warrior */}
            <section className="cropSec_home">
              <div className="container">
                <div className="flex">
                  <div className="colL">
                    <div className="playerImg">
                      <ImageControl folder="images" src={content.image7} />
                    </div>
                    <div className="playerBlk">
                      <div className="playerTitle">
                        <h4>
                          <Text string={content.sec4_card_song_heading} />
                        </h4>
                        <h5>
                          <Text string={content.sec4_card_song_tagline} />
                        </h5>
                      </div>
                      {/* <audio controls="controls">
                        <source src="track.ogg" type="audio/ogg" />
                        <source src="track.mp3" type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio> */}
                      <iframe style={{ borderRadius: "12px" }} src="https://open.spotify.com/embed/track/18geWvX0DY0RUdHfaR1Ozp?utm_source=generator" width="100%" height="240"></iframe>
                    </div>
                  </div>
                  <div className="colR">
                    <div className="image margn_bottom_30">
                      <ImageControl folder="images" src={content.image8} />
                    </div>
                    <h2 className="heading">
                      <Text string={content.sec4_main_heading} />
                    </h2>
                    <p>
                      <Text string={content.sec4_main_desc} />
                    </p>
                    <div className="bTn margn_top_30">
                      <a
                        href={content.sec4_left_left_button_link}
                        target="_blank"
                        className="webBtn blankBtn"
                      >
                        <Text string={content.sec4_left_left_button_text} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* crownLuv */}
            <section className="muzik">
              <div className="contain  text-center">
                <div className="image">
                  <ImageControl folder="images" src={content.image9} />
                </div>
                <div className="cntntt">
                  <h1 className="heading">
                    <Text string={content.sec5_heading} />
                  </h1>
                  <p>
                    <Text string={content.sec5_des} />
                  </p>
                </div>
              </div>
            </section>
            {/* map */}
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default OurSponersContainer;

import http from "../../helpers/http";

import {
  FETCH_FACTS_AND_STATS_CONTENT_SUCCESS,
  FETCH_FACTS_AND_STATS_CONTENT_FAILED,
  FETCH_NEWSAPI_NEWS_SUCCESS,
  FETCH_NEWSAPI_NEWS_FAILED
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
  http
    .get("current-affairs")
    .then(({ data }) => {
      dispatch({
        type: FETCH_FACTS_AND_STATS_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_FACTS_AND_STATS_CONTENT_FAILED,
        payload: error
      });
    });
};

export const fetchNews = () => (dispatch) => {
  http
    .get(
      "https://newsapi.org/v2/everything?q=trafficking&apiKey=d74c9071987d40d9b06feaaa4d8fa2fd"
    )
    .then(({ data }) => {
      dispatch({
        type: FETCH_NEWSAPI_NEWS_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_NEWSAPI_NEWS_FAILED,
        payload: error
      });
    });
};

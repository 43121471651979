import React, { useState } from "react";
import Text from './Text';
import { useForm } from "react-hook-form";
import { doObjToFormData, postData } from "../helpers/helpers";
import { TOAST_SETTINGS } from "../utils/siteSettings";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage } from '@hookform/error-message';
import 'react-toastify/dist/ReactToastify.css';
export default function SubscribeForm({ content }) {
    const [subscribeState, setSubscribeState] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });
    const onSubmit = async form_data => {
        setSubscribeState(true);
        await postData("api/auth/save-subscribe", doObjToFormData(form_data)).then((data) => {
            setSubscribeState(false);
            if (data.status === 1) {
                toast.success(data.msg,
                    TOAST_SETTINGS
                );
            }
            else {
                toast.error(data.msg,
                    TOAST_SETTINGS
                );
            }
        });
    }
    return <>
        <ToastContainer />
        <form action="" method="POST" onSubmit={handleSubmit(onSubmit)} className="smform">
            <div className="inside involvedForm">
                <input
                    type="text"
                    name="email"
                    id="email"
                    className="txtBox"
                    placeholder={content.section7_field_title !== null && content.section7_field_title !== undefined && content.section7_field_title !== '' ? content.section7_field_title : 'Enter your email address'}
                    {...register("email", {
                        required: "Required", pattern: {
                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                            message: "Email format is not valid!"
                        }
                    })}
                />

                <button type="submit" className="webBtn" disabled={(subscribeState === true) ? 'disabled' : ''}>
                    <Text string={content.section7_btn_text !== '' && content.section7_btn_text !== null && content.section7_btn_text !== undefined ? content.section7_btn_text : 'Subscribe'} />{(subscribeState === true) ? <><i className="spinner"></i></> : ''}
                </button>
            </div>
            <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
            />
        </form>
    </>
}

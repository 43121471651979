import React, { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/corporatePatners";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import useScrollTop from "../hooks/useScrollTop";
import BecomePartner from "../components/become-partner";

const CorporatePartnersContainer = () => {
  const data = useSelector((state) => state.corporatePatners.content);
  const isLoading = useSelector((state) => state.corporatePatners.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  useDocumentTitle(data.page_title);
  useScrollTop();

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index">
            <div className="mini-label">
              <div className="contain">
                <ul>
                  <li>
                    <Link to="/donate-now">How to fight</Link>
                  </li>
                  <li>
                    <Link to="/corporate-partners">CORPORATE PARTNERSHIPS</Link>
                  </li>
                </ul>
              </div>
            </div>
            <section
              className="sub-banner corporate-page"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="cntnt text-center">
                  <h1>
                    <Text string={content.banner_heading} />
                  </h1>
                  <Text string={content.banner_desc} parse={true} />
                  <div className="bTn margn_top_30">
                    <Link
                      to={content.banner_button_link}
                      className="webBtn lgBtn"
                    >
                      <Text string={content.banner_button_title} />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="corporate_cntnt">
              <div className="contain">
                <div className="cntntt text-center">
                  <p>
                    <Text string={content.sec2_desc} parse={true} />
                  </p>
                </div>
              </div>
            </section>
            {/* corporate content */}
            <section
              className="impact_counter"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image3)})`
              }}
            >
              <div className="contain  text-center">
                <div className="content">
                  <h1 className="heading">
                    <Text string={content.sec3_main_heading} />
                  </h1>
                </div>
                <div className="flexRow flex">
                  <div className="col">
                    <div className="inner">
                      {/* <h2><span className="count" data-count="1505">0</span></h2> */}
                      <h1 className="heading">
                        <Text string={content.sec3_heading1} />
                      </h1>
                      <h5>
                        <Text string={content.sec3_tagline1} />
                      </h5>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inner">
                      {/* <h2><span className="count" data-count="820">0</span></h2> */}
                      <h1 className="heading">
                        <Text string={content.sec3_heading2} />
                      </h1>
                      <h5>
                        <Text string={content.sec3_tagline2} />
                      </h5>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inner">
                      {/* <h2><span className="count" data-count="6">0</span></h2> */}
                      <h1 className="heading">
                        <Text string={content.sec3_heading3} />
                      </h1>
                      <h5>
                        <Text string={content.sec3_tagline3} />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* imapct counter */}
            <section className="become_partner">
              <div className="contain">
                <div className="flex">
                  <div className="coll">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image2}
                        isLazy={true}
                      />
                    </div>
                  </div>
                  <div className="coll">
                    <BecomePartner content={content} />
                  </div>
                </div>
              </div>
            </section>
            {/* Become partners */}
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default CorporatePartnersContainer;

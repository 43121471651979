import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/traffikAndSex";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import StaticImage from "../components/StaticImage";
import Partner from "../components/Partner";
import SocialIconCard from "../components/SocialIconCard";
import StatCard from "../components/StatCard";
import useScrollTop from "../hooks/useScrollTop";
import { Link } from "react-router-dom";
import SubscribeForm from "../components/subscribe";

const TraffikAndSexContainer = () => {
  const data = useSelector((state) => state.traffikAndSex.content);
  const isLoading = useSelector((state) => state.traffikAndSex.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings, partners } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  useDocumentTitle(data.page_title);
  useScrollTop();

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index" sponsorship>
            <section
              className="trafikBanner"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="flex">
                  <div className="colL">
                    <h1>
                      <Text string={content.banner_heading} />
                    </h1>
                    <Text string={content.banner_desc} parse={true} />
                    <div className="bTn">
                      <Link className="webBtn" to={content.banner_button_link}>
                        <Text string={content.banner_button_title} />
                      </Link>
                    </div>
                  </div>
                  <div className="colR">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image2}
                        isThumb={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* banner */}
            {/* <section className="half-slider">
              <div className="contain">
                <div className="h-slider">
                  <div id="owl-video" className="owl-carousel owl-theme">
                    <div className="inner">
                      <div className="image">
                        <div className="videoBlk">
                          <video poster="images/vid.png">
                            <source src="videos/work.mp4" type="video/mp4" />
                          </video>
                          <div className="videoBtn  fi-play" />
                        </div>
                      </div>
                      <div className="txt">
                        <h4>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="inner">
                      <div className="image">
                        <div className="videoBlk">
                          <video poster="images/vid.png">
                            <source src="videos/work.mp4" type="video/mp4" />
                          </video>
                          <div className="videoBtn fi-play" />
                        </div>
                      </div>
                      <div className="txt">
                        <h4>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="inner">
                      <div className="image">
                        <div className="videoBlk">
                          <video poster="images/vid.png">
                            <source src="videos/work.mp4" type="video/mp4" />
                          </video>
                          <div className="videoBtn fi-play" />
                        </div>
                      </div>
                      <div className="txt">
                        <h4>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor
                        </h4>
                        <p>
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam erat.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* banner */}
            <section className="imagesGrid">
              <div className="contain">
                <div className="flex">
                  <div className="_col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image12}
                        isThumb={true}
                      />
                    </div>
                    <p>
                      <Text string={content.sec_image_detail1} />
                    </p>
                  </div>
                  <div className="_col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image13}
                        isThumb={true}
                      />
                    </div>
                    <p>
                      <Text string={content.sec_image_detail2} />
                    </p>
                  </div>
                  <div className="_col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image14}
                        isThumb={true}
                      />
                    </div>
                    <p>
                      <Text string={content.sec_image_detail3} />
                    </p>
                  </div>
                  <div className="_col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image15}
                        isThumb={true}
                      />
                    </div>
                    <p>
                      <Text string={content.sec_image_detail4} />
                    </p>
                  </div>
                  <div className="_col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image16}
                        isThumb={true}
                      />
                    </div>
                    <p>
                      <Text string={content.sec_image_detail5} />
                    </p>
                  </div>
                  <div className="_col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image17}
                        isThumb={true}
                      />
                    </div>
                    <p>
                      <Text string={content.sec_image_detail6} />
                    </p>
                  </div>
                  <div className="_col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image18}
                        isThumb={true}
                      />
                    </div>
                    <p>
                      <Text string={content.sec_image_detail7} />
                    </p>
                  </div>
                  <div className="_col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image19}
                        isThumb={true}
                      />
                    </div>
                    <p>
                      <Text string={content.sec_image_detail8} />
                    </p>
                  </div>
                  <div className="_col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image20}
                        isThumb={true}
                      />
                    </div>
                    <p>
                      <Text string={content.sec_image_detail9} />
                    </p>
                  </div>
                  <div className="_col">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image21}
                        isThumb={true}
                      />
                    </div>
                    <p>
                      <Text string={content.sec_image_detail10} />
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="aboutSec">
              <div className="contain">
                <div className="flex">
                  <div className="colL">
                    <div className="image">
                      <div className="innerImg">
                        <StaticImage src="shape1.png" />
                      </div>
                      <div className="outerImg">
                        <ImageControl
                          folder="images"
                          src={content.image3}
                          isThumb={true}
                          isLazy={true}
                        />
                      </div>
                    </div>
                    <div className="whiteLbl flex">
                      <div className="smImage">
                        <ImageControl folder="images" src={content.image4} />
                      </div>
                      <div className="cntnt">
                        <h3>
                          <Text string={content.sec4_left_card_heading} />
                        </h3>
                        <h3>
                          <Text string={content.sec4_left_card_short} />
                        </h3>
                      </div>
                    </div>
                    <div className="smallInfo flex">
                      <p>
                        <Text string={content.sec4_left_card_tagline} />
                      </p>
                      <span>
                        <StaticImage src="info.png" />
                      </span>
                    </div>
                    <div className="bTn">
                      <Link
                        className="webBtn"
                        to={content.sec4_left_left_button_link}
                      >
                        <Text string={content.sec4_left_left_button_text} />
                        <StaticImage src="arrow.svg" />
                      </Link>
                      <a
                        className="webBtn emptyBtn"
                        href={content.sec4_left_right_button_link}
                        target="_blank"
                      >
                        <StaticImage src="playp.svg" />
                        <Text string={content.sec4_left_right_button_text} />
                      </a>
                    </div>
                  </div>
                  <div className="colR">
                    <h1>
                      <Text string={content.sec4_right_main_heading} />
                    </h1>
                    <p>
                      <Text string={content.sec4_right_main_desc} />
                    </p>
                    <h3>
                      <Text string={content.sec4_right_heading_2} />
                    </h3>
                    <ul>
                      <li>
                        <span>
                          <Text string={content.sec4_right_card1_no} />
                        </span>
                        <p>
                          <Text string={content.sec4_right_card1_desc} />
                        </p>
                      </li>
                      <li>
                        <span>
                          <Text string={content.sec4_right_card2_no} />
                        </span>
                        <p>
                          <Text string={content.sec4_right_card2_desc} />
                        </p>
                      </li>
                      <li>
                        <span>
                          <Text string={content.sec4_right_card3_no} />
                        </span>
                        <p>
                          <Text string={content.sec4_right_card3_desc} />
                        </p>
                      </li>
                      <li>
                        <span>
                          <Text string={content.sec4_right_card4_no} />
                        </span>
                        <p>
                          <Text string={content.sec4_right_card4_desc} />
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="partner">
              <div className="contain">
                <div className="text-center">
                  <h2>
                    <Text string={content.section5_heading} />
                  </h2>
                </div>
                <div className="flex">
                  {partners &&
                    partners.map((partner) => <Partner partner={partner} />)}
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="cropSec">
              <div className="contain">
                <div className="flex">
                  <div className="colL">
                    <h2>
                      <Text string={content.sec6_main_heading} />
                    </h2>
                    <p>
                      <Text string={content.sec6_main_desc} />
                    </p>
                    <div className="playerBlk">
                      <div className="playerTop flex">
                        <div className="playerImg">
                          <ImageControl folder="images" src={content.image6} />
                        </div>
                        <div className="playerTitle">
                          <h4>
                            <Text string={content.sec6_card_song_heading} />
                          </h4>
                          <h5>
                            <Text string={content.sec6_card_song_tagline} />
                          </h5>
                        </div>
                      </div>
                      {/* <audio controls="controls">
                        <source src="track.ogg" type="audio/ogg" />
                        <source src="track.mp3" type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio> */}
                      <iframe style={{ borderRadius: "12px" }} src="https://open.spotify.com/embed/track/18geWvX0DY0RUdHfaR1Ozp?utm_source=generator" width="100%" height="240"></iframe>
                    </div>
                  </div>
                  <div className="colR">
                    <div className="image">
                      <ImageControl folder="images" src={content.image7} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="involved">
              <div className="ab_image ab_imageBottom">
                {/* <img src="images/5.png" /> */}
              </div>
              <div className="contain">
                <div className="hire_div">
                  <div className="hire-description">
                    <h2 className="heading_title">
                      <Text string={content.section7_heading} />
                    </h2>
                    <p>
                      <Text string={content.section7_detail} />
                    </p>
                    <SubscribeForm content={content} />
                    <div className="flex involedSocail">
                      <SocialIconCard src={content.image8} link={content?.link8} />
                      <SocialIconCard src={content.image9} link={content?.link9} />
                      <SocialIconCard src={content.image10} link={content?.link10} />
                      <SocialIconCard src={content.image11} link={content?.link11} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* banner */}
            <section id="join">
              <div className="contain">
                <div className="secHeading text-center">
                  <h2>
                    <Text string={content.sec8_heading} />
                  </h2>
                </div>
                <div className="flex">
                  <StatCard
                    heading={content.sec8_card_heading1}
                    detail={content.sec8_card_desc1}
                  />
                  <StatCard
                    heading={content.sec8_card_heading2}
                    detail={content.sec8_card_desc2}
                  />
                  <StatCard
                    heading={content.sec8_card_heading3}
                    detail={content.sec8_card_desc3}
                  />
                  <StatCard
                    heading={content.sec8_card_heading4}
                    detail={content.sec8_card_desc4}
                  />
                </div>
                <div className="secHeading text-center">
                  <h2>
                    <Text string={content.sec8_after_card_heading} />
                  </h2>
                </div>
                <div className="joinSubHeading text-center">
                  <h3>
                    <Text string={content.sec8_after_card_heading_tagline} />
                  </h3>
                  <h1>
                    <Text string={content.sec8_after_card_amount} />
                  </h1>
                  <h4>
                    <Text string={content.sec8_after_card_amount_tagline} />
                  </h4>
                </div>
                <div className="bTn text-center joinDonateBtn">
                  <Link className="webBtn" to={content.sec8_button_link}>
                    <Text string={content.sec8_button_text} />
                  </Link>
                </div>
              </div>
            </section>
            {/* banner */}
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default TraffikAndSexContainer;

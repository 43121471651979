import http from "../../helpers/http";

import {
  FETCH_OUR_SPONSORS_CONTENT_SUCCESS,
  FETCH_OUR_SPONSORS_CONTENT_FAILED
} from "../actions/actionTypes";

export const fetchData = () => (dispatch) => {
  http
    .get("our-sponsors")
    .then(({ data }) => {
      dispatch({
        type: FETCH_OUR_SPONSORS_CONTENT_SUCCESS,
        payload: data
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_OUR_SPONSORS_CONTENT_FAILED,
        payload: error
      });
    });
};

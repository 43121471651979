import React from "react";
import * as paths from "../constants/paths";
const _ = require("lodash");

const ContentBannerVideo = ({ poster, video, muted = true }) => {
    let url = paths.API_UPLOADS_URL; //temp
    video = `${url}${'images'}/${video}`;
    poster = `${url}${'images'}/${poster}`;
    console.log(poster, video)
    return (
        <>
            <video
                poster={_.isEmpty(poster) || poster}
                preload="yes"
                controls
                muted={muted}
                playsInline
                loop
                autoPlay={true}
                src={_.isEmpty(video) || video}
                width="100%"
                height="100%"
            >
                <source src={_.isEmpty(video) || video} type="video/mp4" />
            </video>
        </>
    );
};

export default ContentBannerVideo;

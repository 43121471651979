import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/privacy_policy";
import LoadingScreen from "../components/LoadingScreen";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useScrollTop from "../hooks/useScrollTop";
import Text from "../components/Text";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";

export default function PrivacyPolicy() {
    const data = useSelector((state) => state.privacy_policy.content);
    console.log(data)
    const isLoading = useSelector((state) => state.privacy_policy.isLoading);
    const dispatch = useDispatch();
    const { content, site_settings, details } = data;

    useEffect(() => {
        dispatch(fetchData());
    }, []);
    useEffect(() => {
        document.body.classList.add("privacy_policyBody");
    }, []);

    useDocumentTitle(data.page_title);
    useScrollTop();
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isSiteLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
    if (isLoading || isSiteLoading) return <LoadingScreen isLoading={isLoading} />;
    return <>
        <LoadingScreen isLoading={isLoading} />
        {!isLoading && (
            <div>
                <Header siteSettings={siteSettings} />
                <main className="index">
                    <section className="textual_page">
                        <div className="contain">
                            <div className="text_blk">
                                <div className="sec_heading">
                                    <h2>{content?.heading}</h2>
                                </div>
                                <div className="ckEditor">
                                    <div>
                                        <Text string={details} parse={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer siteSettings={site_settings} />
            </div>

        )}
    </>;
}

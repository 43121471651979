import React, { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../states/actions/startAFundraiser";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import useScrollTop from "../hooks/useScrollTop";
import FundRaiseForm from "../components/fundraise-form";

const StartAFundraiserContainer = () => {
  const data = useSelector((state) => state.startAFundraiser.content);
  const isLoading = useSelector((state) => state.startAFundraiser.isLoading);
  const dispatch = useDispatch();
  const { content, site_settings } = data;

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  useDocumentTitle(data.page_title);
  useScrollTop();

  return (
    <>
      <LoadingScreen isLoading={isLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          <main className="index">
            <div className="mini-label">
              <div className="contain">
                <ul>
                  <li>
                    <Link to="/donate-now">How to fight</Link>
                  </li>
                  <li>
                    <Link to="/start-a-fundraiser">Start A Fundraiser</Link>
                  </li>
                </ul>
              </div>
            </div>
            <section
              className="sub-banner corporate-page"
              style={{
                backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
              }}
            >
              <div className="contain">
                <div className="cntnt text-center">
                  <h3>
                    <Text string={content.banner_heading} />
                  </h3>
                  <div className="bTn margn_top_30">
                    <Link
                      to={content.banner_button_link}
                      className="webBtn lgBtn"
                    >
                      <Text string={content.banner_button_title} />
                    </Link>
                  </div>
                </div>
              </div>
            </section>
            {/* banner */}
            <section className="fund_raiser">
              <div className="contain">
                <div className="flexRow flex">
                  <div className="col col1">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image2}
                        isThumb={true}
                      />
                    </div>
                  </div>
                  <div className="col col2">
                    <div className="contennt">
                      <h2 className="heading">
                        <Text string={content.sec2_first_right_heading} />
                      </h2>
                      <Text
                        string={content.sec2_first_right_desc}
                        parse={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="flexRow flex">
                  <div className="col col1">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image3}
                        isThumb={true}
                      />
                    </div>
                  </div>
                  <div className="col col2">
                    <div className="contennt">
                      <h2 className="heading">
                        <Text string={content.sec2_second_left_heading} />
                      </h2>
                      <Text
                        string={content.sec2_second_left_desc}
                        parse={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Fund Raiser */}
            <section className="fund_form">
              <div className="contain">
                <div className="flex">
                  <div className="coll">
                    <div className="image">
                      <ImageControl
                        folder="images"
                        src={content.image4}
                        isLazy={true}
                        isThumb={true}
                      />
                    </div>
                  </div>
                  <div className="coll">
                    <FundRaiseForm content={content} />
                  </div>
                </div>
              </div>
            </section>
            {/* Become partners */}
          </main>
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default StartAFundraiserContainer;

import React from "react";
const _ = require("lodash");

const BannerVideo = ({ poster, video, muted = true }) => {
  return (
    <>
      <video
        poster={_.isEmpty(poster) || poster}
        preload="yes"
        controls
        muted={muted}
        playsInline
        loop
        autoPlay="true"
        src={_.isEmpty(video) || video}
        width="100%"
        height="100%"
      >
        <source src={_.isEmpty(video) || video} type="video/mp4" />
      </video>
    </>
  );
};

export default BannerVideo;

import React, { useEffect } from 'react'
import { useParams } from "react-router-dom";
import LoadingScreen from '../../components/LoadingScreen';
import { getData } from '../../helpers/helpers';
import useDocumentTitle from '../../hooks/useDocumentTitle';
export default function Reset() {
    const { mem_code } = useParams();
    async function verifyEmail() {
        var form_data = new FormData();
        form_data.append('email', true);
        await getData("api/auth/reset", mem_code).then((data) => {
            console.log(data)
            if (data.status === 1) {
                localStorage.setItem("reset_id", data?.authToken);
                window.location.replace("/auth/reset-password");
            }
            else {
                window.location.replace("/auth/signin");
            }
        });
    }
    useEffect(() => {
        verifyEmail()
    }, []);
    useDocumentTitle('Reset Password');
    return (
        <>
            <LoadingScreen isLoading={true} />
        </>
    )
}

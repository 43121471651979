import React from "react";
import Header from "../components/includes/header";
import Profilesection from "../components/dashboard-pages/profile-section";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from '../components/LoadingScreen';
import useDocumentTitle from '../hooks/useDocumentTitle';
const Profile = () => {
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
  const member = siteSettings?.member;
  useDocumentTitle('Profile Settings');
  if (isLoading) return <LoadingScreen isLoading={isLoading} />;
  // console.log(siteSettings)
  if (parseInt(member?.mem_verified) === 0) {
    return window.location.replace("/member-verification");
  }
  return (
    <>
      <style>{"body { background-color: #121b22; }"}</style>
      <Header siteSettings={siteSettings} />
      <Profilesection member={member} />
    </>
  );
};

export default Profile;

import React from "react";

const UpdateEmail = ({ togglePopup, setUpdatePopup }) => {
  return (
    <>
      <section className="cmnpopup popup" style={{ display: "block" }}>
        <div className="tableDv">
          <div className="tableCell">
            <div className="contain">
              <div className="inner">
                <div className="crosBtn" onClick={togglePopup}></div>
                <h2>Change email</h2>
                <form action="">
                  <div className="row formRow">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                      <label for="">Current Email</label>
                      <div className="txtGrp">
                        <input
                          type="email"
                          name=""
                          id=""
                          className="txtBox"
                          placeholder="JsonCrown123@gmail.com"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12">
                      <label for="">New Email</label>
                      <div className="txtGrp">
                        <input
                          type="text"
                          name=""
                          id=""
                          className="txtBox"
                          placeholder="JsonCrown@gmail.com"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bTns">
                    <button
                      type="button"
                      className="webBtn green"
                      onClick={() => setUpdatePopup(true)}
                    >
                      Save
                    </button>
                    <button className="webBtn grey" onClick={togglePopup}>
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// #endregion

export default UpdateEmail;

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchData,
  search,
  clearSearch
} from "../states/actions/nationalDirectory";
import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";
import LoadingScreen from "../components/LoadingScreen";
import { Link, NavLink } from "react-router-dom";
import ImageControl from "../components/ImageControl";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Text from "../components/Text";
import { getBackgroundImageUrl } from "../helpers/helpers";
import StaticImage from "../components/StaticImage";
import FormProcessingSpinner from "../components/FormProcessingSpinner";
// import { Map } from "../components/Map";
import { isEmpty } from "lodash";
import useScrollTop from "../hooks/useScrollTop";
import GoogleMapReact from 'google-map-react';
import LeafLetMap from "../components/leaflet-map";

const NationalDirectoryContainer = () => {

  const data = useSelector((state) => state.nationalDirectory.content);
  const isLoading = useSelector((state) => state.nationalDirectory.isLoading);
  const organizations = useSelector(
    (state) => state.nationalDirectory.organizations
  );
  const isFormProcessing = useSelector(
    (state) => state.nationalDirectory.isFormProcessing
  );
  const dispatch = useDispatch();
  const { content, site_settings, tags } = data;

  const [tag, setTag] = useState("");
  const [zip, setZip] = useState("");

  // REFERENCES
  const zipRef = useRef(null);

  useEffect(() => {
    dispatch(fetchData());
  }, []);
  useEffect(() => {
    document.body.classList.remove('liveTvBody');
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = {
      tag,
      zip
    };
    if (isEmpty(tag) && isEmpty(zip)) {
      zipRef.current.focus();
      return false;
    }
    dispatch(search(formData));
  };

  const clearFilters = (e) => {
    e.preventDefault();
    setTag("");
    setZip("");
    dispatch(clearSearch());
  };
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isSiteLoading = useSelector((state) => state.fetchSiteSettings.isLoading);
  const member = siteSettings?.member;

  useDocumentTitle(data.page_title);
  useScrollTop();
  if (parseInt(member?.mem_verified) === 0) {
    return window.location.replace("/member-verification");
  }

  return (
    <>
      <LoadingScreen isLoading={isLoading || isSiteLoading} />
      {!isLoading && (
        <div>
          <Header siteSettings={site_settings} />
          {
            member?.subscription !== undefined && member?.subscription !== null && member?.subscription?.id > 0 ?
              <main className="index">
                <div className="mini-label">
                  <div className="contain">
                    <ul>
                      <li>
                        <Link to="/help-and-resources">Help &amp; Resources</Link>
                      </li>
                      <li>
                        <Link to="/national-directory">National Directory</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <section
                  className="sub-banner national-directory"
                  style={{
                    backgroundImage: `url(${getBackgroundImageUrl(content.image1)})`
                  }}
                >
                  <div className="contain">
                    <div className="flex">
                      <div className="colL">
                        <div className="cntnt">
                          <h1>
                            <Text string={content.banner_heading} />
                          </h1>
                          <Text string={content.banner_desc} parse={true} />
                        </div>
                      </div>
                      <div className="colR">
                        <div className="help-flex flex">
                          <div className="col">
                            <div className="inner flex">

                            <a href={`tel:${content.right_section_card_tagline_sec1}`}>
                            <div className="help-icon">
                                <ImageControl
                                  folder="images"
                                  src={content.image2}
                                />
                              </div>
                            </a>

                              
                              <div className="cntnt">
                                <h5>
                                  <Text
                                    string={content.right_section_card_heading1}
                                  />
                                </h5>
                                <span>
                                  <a href={`tel:${content.right_section_card_tagline_sec1}`}>

                                  <Text
                                    string={content.right_section_card_tagline1}
                                  />

                                  </a>
                                  
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="inner flex">

                            <a href={`sms:${content.right_section_card_tagline_sec2}`}>
                              <div className="help-icon">
                                <ImageControl
                                  folder="images"
                                  src={content.image3}
                                />
                              </div>

                            </a>

                              <div className="cntnt">
                                <h5>
                                  <Text
                                    string={content.right_section_card_heading2}
                                  />
                                </h5>
                                <span>
                                <a href={`sms:${content.right_section_card_tagline_sec2}`}>
                                  <Text
                                    string={content.right_section_card_tagline2}
                                  />
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="inner flex">
                            <a href={`mailto:${content.right_section_card_tagline_sec3}`}>
                              <div className="help-icon">
                                <ImageControl
                                  folder="images"
                                  src={content.image4}
                                />
                              </div>
                              </a>
                              <div className="cntnt">
                                <h5>
                                  <Text
                                    string={content.right_section_card_heading3}
                                  />
                                </h5>
                                <span>
                                <a href={`mailto:${content.right_section_card_tagline_sec3}`}>
                                  <Text
                                    string={content.right_section_card_tagline3}
                                  />
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* banner */}
                <section className="national-directory-search-blk">
                  <div className="contain">
                    <div className="outer_search_blk">
                      <h3>Search for Anti-trafficking organisations near you</h3>
                      <ul className="filter_options">
                        {tags.map((t, index) => (
                          <li className={t.id == tag ? "active" : ""} key={index}>
                            <a
                              onClick={() => setTag(t.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <span className="text_label">{t.title}</span>
                              <span className="number_label">{t.num}</span>
                              <span className="symbol_icon">
                                <StaticImage src="plus.png" alt="" />
                              </span>
                            </a>
                          </li>
                        ))}
                        <li>
                          <a
                            onClick={clearFilters}
                            className="_btn"
                            style={{ cursor: "pointer" }}
                            disabled={isEmpty(tag) && isEmpty(zip) ? true : false}
                          >
                            <span className="text_label">Clear Filters</span>
                          </a>
                        </li>
                      </ul>
                      <div className="_form">
                        <div className="relative">
                          <input
                            type="text"
                            name="zip"
                            placeholder="Enter zipcode"
                            className="txtBox"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            ref={zipRef}
                          />
                          <button
                            type="button"
                            className="webBtn"
                            onClick={handleSubmit}
                            disabled={isFormProcessing ? true : false}
                          >
                            Search
                            <FormProcessingSpinner
                              isFormProcessing={isFormProcessing}
                            />
                          </button>
                        </div>
                      </div>
                      <div className="service_blk">
                        <div className="flex">
                          <div className="colL">
                            <div className="inner">
                              <ul className="scrollbar">
                                {isEmpty(organizations) ? (
                                  <li>no record found.</li>
                                ) : (
                                  organizations.map((o, index) => (
                                    <li key={index}>
                                      <a>{o.name}</a>
                                    </li>
                                  ))
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="colR relative">
                            <div className="outer_map">
                              <div id="map_canvas">
                                <LeafLetMap markers={organizations} />
                              </div>
                            </div>

                            {/* <Map organizations={organizations} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="txt_blk">
                        <Text string={content.last_detail} parse={true} />
                      </div>
                    </div>
                  </div>
                </section>
              </main>
              :
              <main className="liveMain subscribe_upgrade">
                <section className="innerBox">
                  <h3>Subscribe Now</h3>
                  <p>If you want to access national directory you please subscribe Now.</p>
                  <div className="bTn text-center">
                    <NavLink to="/subscription" className="webBtn colorBtn">Subscription Plans</NavLink>
                  </div>
                </section>
              </main>
          }
          <Footer siteSettings={site_settings} />
        </div>
      )}
    </>
  );
};

export default NationalDirectoryContainer;

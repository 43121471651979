import React from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ErrorMessage } from '@hookform/error-message';
import { postData } from '../../helpers/helpers';
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export default function AddSubscriptionBlk({ register, errors, handleCardDetailsChange, checkoutError, handleSubmit, setProcessing, isProcessing, setCheckoutError, setPopup, plan }) {
    const stripe = useStripe();
    const elements = useElements()
    const paymentProcess = async (subscriptionId, clientSecret, payment_method, formPostData) => {
        const confirmPayment = await stripe.confirmCardPayment(clientSecret, {
            payment_method: payment_method
        });
        if (confirmPayment.error) {
            setCheckoutError(confirmPayment.error.message);
            setProcessing(false);
            return;
        }
        else {
            if (confirmPayment.paymentIntent.status === "succeeded") {
                var form_data = new FormData();
                for (var key in formPostData) {
                    form_data.append(key, formPostData[key]);
                }
                form_data.append('plan_id', plan?.id);
                form_data.append('subscription_id', subscriptionId);
                form_data.append('payment_method', payment_method);
                let postUrl = '';

                await postData("api/auth/confirm-subscription/", form_data).then((data) => {
                    console.log(data)
                    if (data?.status === 1) {
                        toast.success(data?.msg, {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(() => {
                            window.location.replace("/subscription");
                        }, 2000);
                        return;
                    }
                    else {
                        toast.error(data?.msg, {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        return;
                    }
                })
            }
            else {
                setCheckoutError('Subscription could not be completed!');
            }
        }
    }
    const onSubmit = async (formPostData) => {
        setProcessing(true);
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const cardElement = elements.getElement(CardElement);
        try {
            const paymentMethodReq = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
                // billing_details: billingDetails
            });
            if (paymentMethodReq.error) {
                setCheckoutError(paymentMethodReq.error.message);
                setProcessing(false);
                return;
            }
            else {
                let paymentMethodId = paymentMethodReq.paymentMethod.id;
                var form_data = new FormData();
                for (var key in formPostData) {
                    form_data.append(key, formPostData[key]);
                }
                form_data.append('amount', plan?.plan_price);
                form_data.append('plan_id', plan?.id);
                form_data.append('payment_method_id', paymentMethodId);
                await postData("api/auth/create-subscription", form_data).then((data) => {
                    console.log(data)
                    if (data?.status === 1) {
                        // console.log(data)
                        paymentProcess(data?.subscription_id, data?.client_secret, paymentMethodId, formPostData);
                    } else {
                        setCheckoutError(data?.msg);
                        setProcessing(false);
                        return;
                    }
                })
            }
        }
        catch (err) {
            setCheckoutError(err.message);
        }
    }
    return (
        <>
            <ToastContainer />
            <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
                <div className="row formRow">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12 margn_top_20">
                        <div className="txtGrp">
                            <label for="">Name on Card</label>
                            <input
                                type="text"
                                name="card_holder"
                                id="card_holder"
                                className="txtBox"
                                placeholder="Json"

                                {...register("card_holder", {
                                    pattern: {
                                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                                        message: 'Invalid value',
                                    },
                                    required: "Required"
                                })}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="card_holder"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xx-12 margn_top_20">
                        <div className="txtGrp">
                            <label for="">Name on Card</label>
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '14px',
                                            color: '#424770',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}
                                onChange={handleCardDetailsChange}
                            />
                            {checkoutError && <div className="alert alert-danger">{checkoutError}</div>}
                        </div>
                    </div>
                </div>
                <div className="bTn text-center" style={{ marginTop: "15px" }}>
                    <button type='button' className='webBtn' onClick={() => setPopup(false)}>Back</button>
                    <button
                        type="submit"
                        className="webBtn green"
                        disabled={(isProcessing === true) ? 'disabled' : ''}
                    >
                        {(isProcessing === true) ? <><i className="spinner"></i></> : "Subscribe Now!"}
                    </button>

                </div>
            </form>
        </>
    )
}

import React from "react";
import ImageControl from "./ImageControl";

const Partner = ({ partner }) => {
  return (
    <>
      <div className="col">
        <a href={partner.external_link} target="_blank" className="image">
          <ImageControl src={partner.image} folder="partners" />
        </a>
      </div>
    </>
  );
};

export default Partner;
